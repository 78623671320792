import React from "react";
import MAdminCardFooterItem from "../molecules/m-admin-card-footer-item";
import OExpenseModal from "./o-expense-modal";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import OWithdrawModal from "./o-withdraw-modal";
import { useNavigate } from "react-router-dom";
import { can } from "../../utils/security";

const OAdminCardFooter = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const handleOnClickExpense = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OExpenseModal
            queryDate={queryDate}
            routeId={defaultRoute.id}
            onClose={onClose}
          />
        );
      },
    });
  };
  const handleOnClickWithdraw = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OWithdrawModal
            queryDate={queryDate}
            routeId={defaultRoute.id}
            onClose={onClose}
          />
        );
      },
    });
  };
  const handleOnClickCollection = () => {
    navigate("/collections/create");
  };

  return (
    <div className="wallet-footer">
      {can("api-expenses-create_expense") && (
        <MAdminCardFooterItem
          icon="alert-circle-outline"
          text="Gasto"
          color="bg-danger"
          onClick={handleOnClickExpense}
        />
      )}
      {can("api-withdrawls-create_withdraw") && (
        <MAdminCardFooterItem
          icon="arrow-down-circle-outline"
          text="Retiro"
          color="bg-warning"
          onClick={handleOnClickWithdraw}
        />
      )}
      {can("api-liquidations-create_liquidation") && (
        <MAdminCardFooterItem
          icon="document-lock-outline"
          text="Liquidar"
          color="bg-success"
          onClick={handleOnClickCollection}
        />
      )}
    </div>
  );
};

export default OAdminCardFooter;
