/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import OHeaderListPage from "../../components/organisms/o-header-list-page";
import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

const ReportsPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  const [filter, setFilter] = useState("");

  const reports = [
    {
      header: "Clientes",
      reportName: "Consultar abonos",
      description:
        "Consulta los abonos que han realizado los clientes en una ruta",
      path: "/reports/customers/payments-history",
    },
  ];

  const handleOnClick = (path: string) => {
    navigate(path);
  };

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListPage
        path="/dashboard"
        title="Informes"
        filter={filter}
        dispatch={setFilter}
        hideRightIcon={true}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>
        <ul className="listview image-listview inset">
          {reports.map((report: any, index: number) => {
            return (
              <li className="listview--border" key={`report-${index}`}>
                <div className="item">
                  <div className="in">
                    <div className="uppercase">
                      <header>{report.header}</header>
                      <span>{report.reportName}</span>
                      <footer>{report.description}</footer>
                    </div>
                    <span
                      onClick={() => handleOnClick(report.path)}
                      className="text-muted text-muted--center"
                    >
                      Ir
                      <IonIcon icon={chevronForwardOutline} />
                    </span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ReportsPage;
