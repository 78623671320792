import React from "react";
import AdminDashboardPage from "./admin";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const DashboardPage = () => {
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <AdminDashboardPage />
    </>
  );
};

export default DashboardPage;
