import { get } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";

export const getPaymentsHistory = async (
  routeId: number,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "payments/reports/history",
      params: { ...params, routeId },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
