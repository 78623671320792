import React, { useEffect, useRef, useState } from "react";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import { SubmitHandler, useForm } from "react-hook-form";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import { useNavigate, useParams } from "react-router-dom";
import { alertError, alertSuccess } from "../../utils/alerts";
import { getUserCollectors } from "../users/services";
import { IEditRoute } from "./types";
import { editRoute, getRoute } from "./services";
import { useSelector } from "react-redux";
import Select from "react-select";
import { DEFAULT_LIMIT } from "../../utils/constants";

const EditRoutePage = () => {
  const navigate = useNavigate();

  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );

  const [collectors, setCollectors] = useState([]);
  const [collectorId, setCollectorId] = useState(null);
  const [selectedOption, setSelectedOption] = useState({} as any);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditRoute>({
    defaultValues: async () => {
      if (defaultRoute?.id) {
        const response = await getRoute(Number(defaultRoute.id));

        if (response.data.items.length) {
          setCollectorId(response.data.items[0].collectorId);
          setSelectedOption({
            label: response.data.items[0].collectorName,
            value: response.data.items[0].collectorId,
          });
          return response.data.items[0] as IEditRoute;
        }
      }

      return {} as IEditRoute;
    },
  });

  const onSubmit: SubmitHandler<IEditRoute> = async (input) => {
    if (!collectorId) {
      return alertError("Debe seleccionar un Cobrador");
    }

    const result = await editRoute(input.id, {
      ...input,
      collectorId,
    });

    if (result.status === true) {
      alertSuccess(result.message);
      navigate("/loans");
    } else {
      alertError(result.message);
    }
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };
  const loadCollectors = async () => {
    const collectors = await getUserCollectors({
      page: 1,
      limit: DEFAULT_LIMIT,
    });
    setCollectors(
      collectors.data.items.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  useEffect(() => {
    loadCollectors();
  }, []);

  return (
    <>
      <OHeaderBackSave
        title="Editar ruta"
        pathToBack="/loans"
        onSave={handleOnSave}
      />

      <div className="form-custom">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button
            ref={buttonRef}
            type="submit"
            id="submit"
            style={{ display: "none" }}
          />
          <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="label" htmlFor="name">
                Nombre
              </label>
              <input
                {...register("name", { required: true, maxLength: 15 })}
                type="text"
                className="form-control"
                id="name"
              />
            </div>
            {errors.name && <AFieldErrorMessage />}
          </div>

          <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="label" htmlFor="collector">
                Cobrador
              </label>
              <Select
                value={selectedOption}
                options={collectors}
                onChange={(option: any) => {
                  setCollectorId(option.value);
                  setSelectedOption(option);
                }}
              />
            </div>
            {errors.collectorId && <AFieldErrorMessage />}
          </div>
        </form>
      </div>
    </>
  );
};

export default EditRoutePage;
