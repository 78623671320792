import React from "react";
import {
  Box,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Modal,
} from "@mui/material";
import { IInputOSelectRouteModal } from "../types/IInputsComponents";
import { IRouteList } from "../../pages/routes/types";
import { PREFIX_AUTH } from "../../pages/login/services/auth";
import { useDispatch } from "react-redux";
import { setDefaultRoute } from "../../slices/default-route";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "5px",
};

const OSelectRouteModal = (props: IInputOSelectRouteModal) => {
  const dispatch = useDispatch();

  const handleOnClick = async (route: IRouteList): Promise<void> => {
    localStorage.setItem(`${PREFIX_AUTH}-route`, JSON.stringify(route));
    await dispatch(setDefaultRoute(route) as any);
    window.location.reload();
  };

  return (
    <>
      <Modal open={props.isOpen}>
        <Fade in={props.isOpen}>
          <Box sx={style}>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Selección de ruta</h5>
                <h6 className="card-subtitle mb-1">
                  Seleccionar ruta por defecto
                </h6>
                <div className="card-text">
                  <List>
                    {props.routes.map((route: IRouteList) => {
                      return (
                        <ListItemButton
                          key={`item-route-${route.id}`}
                          onClick={() => handleOnClick(route)}
                        >
                          <ListItemText
                            primary={route.collectorName}
                            secondary={`Ruta: ${route.name}`}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default OSelectRouteModal;
