import axios, { AxiosResponse } from "axios";
import {
  IBadRequestResponse,
  IGetInput,
  IPostInput,
  IPutInput,
  IResponse,
} from "./types";
import { PREFIX_AUTH } from "../pages/login/services/auth";
import { CathErrorResponse } from "../utils/catch-error-response";

const API = process.env.REACT_APP_MS_COPP_API;

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    if (error.response.status == 401 || error.response.status == 419) {
      localStorage.removeItem(`${PREFIX_AUTH}-token`);
      localStorage.removeItem(`${PREFIX_AUTH}-data`);
      localStorage.removeItem(`${PREFIX_AUTH}-route`);
      localStorage.removeItem(`${PREFIX_AUTH}-query-date`);
      return window.location.href = "/auth";
    }

    return Promise.reject(error);
  }
);

const bearerToken = (): string | null => {
  const token = localStorage.getItem(`${PREFIX_AUTH}-token`);
  return token ? `Bearer ${token.replaceAll('"', "")}` : null;
};

const lenderHeader = (): string | null => {
  const lender = localStorage.getItem(`${PREFIX_AUTH}-lender`);
  return lender ? lender.replaceAll('"', "") : null;
};

const loader = (isEnable: boolean) => {
  if (isEnable) {
    document
      ?.getElementById("loader")
      ?.setAttribute(
        "style",
        "pointer-events: none; opacity: 0.5; transition: 0.2s ease-in-out;"
      );
  } else {
    document?.getElementById("loader")?.setAttribute("style", "display: none;");
  }
};

export const login = async (input: IPostInput): Promise<IResponse> => {
  try {
    const endpoint = `${API}/api/${input.endpoint}`;

    loader(true);
    await axios.get(`${API}/sanctum/csrf-cookie`);
    const result = await axios.post(endpoint, input.body);

    return result.data as any as IResponse;
  } catch (error: any) {
    throw new Error(CathErrorResponse(error));
  } finally {
    loader(false);
  }
};

export const post = async (
  input: IPostInput
): Promise<IResponse | IBadRequestResponse> => {
  try {
    loader(true);
    const endpoint = `${API}/api/${input.endpoint}`;
    const headers = {
      Authorization: bearerToken(),
    };
    const result = await axios.post(endpoint, input.body, { headers });

    return result.data as any as IResponse;
  } catch (error: any) {
    throw new Error(CathErrorResponse(error));
  } finally {
    loader(false);
  }
};

export const get = async (input: IGetInput): Promise<IResponse> => {
  try {
    loader(true);

    const endpoint = `${API}/api/${input.endpoint}`;
    const headers = {
      Authorization: bearerToken(),
    };
    const result = await axios.get(endpoint, { params: input.params, headers });

    return {
      status: !!result,
      data: result.data,
      message: !!result ? "OK" : "Error",
    } as IResponse;
  } catch (error: any) {
    throw new Error(error.message);
  } finally {
    loader(false);
  }
};

export const put = async (
  input: IPutInput
): Promise<IResponse | IBadRequestResponse> => {
  try {
    loader(true);
    const endpoint = `${API}/api/${input.endpoint}`;
    const headers = {
      Authorization: bearerToken(),
    };
    const result = await axios.put(endpoint, input.body, { headers });

    return result.data as IResponse;
  } catch (error: any) {
    throw new Error(CathErrorResponse(error));
  } finally {
    loader(false);
  }
};
