import { configureStore } from "@reduxjs/toolkit";
import messageReducer from "./messages";
import drawerReducer from "./drawer";
import authReducer from "./auth";
import defaultRouteReducer from "./default-route";
import queryDateReducer from "./query-date";
import notificationsReducer from "./notifications";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  drawer: drawerReducer,
  defaultRoute: defaultRouteReducer,
  queryDate: queryDateReducer,
  notifications: notificationsReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
