import React from "react";
import { IInputAdminCardFooterItem } from "../types/IInputsComponents";
import { IonIcon } from "@ionic/react";
import {
  helpOutline,
  alertCircleOutline,
  arrowDownCircleOutline,
  documentLockOutline,
} from "ionicons/icons";

const MAdminCardFooterItem = (props: IInputAdminCardFooterItem) => {
  let icon = helpOutline;
  switch (props.icon) {
    case "arrow-down-circle-outline":
      icon = arrowDownCircleOutline;
      break;
    case "alert-circle-outline":
      icon = alertCircleOutline;
      break;
    case "document-lock-outline":
      icon = documentLockOutline;
      break;
  }

  const htmlClass = `icon-wrapper ${props.color}`;

  return (
    <>
      <div className="item">
        <span onClick={props.onClick}>
          <div className={htmlClass}>
            <IonIcon className="ion-icon" icon={icon} />
          </div>
          <strong>{props.text}</strong>
        </span>
      </div>
    </>
  );
};

export default MAdminCardFooterItem;
