import React from "react";
import "./styles.css";
import { ILogoParams } from "./types/ILogoParams";

const ALogo = ({ width = 65, height = 65 }: ILogoParams) => {
  return (
    <div className="logo">
      <img src="/assets/img/icon/128x128.png" alt="" />
    </div>
  );
};

export default ALogo;
