// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDEns5YRbT-djwiDE6E6hxtODJl6FkA6sQ",
  authDomain: "payer-app-bc37e.firebaseapp.com",
  projectId: "payer-app-bc37e",
  storageBucket: "payer-app-bc37e.appspot.com",
  messagingSenderId: "424150192721",
  appId: "1:424150192721:web:ef3132b60aeeeb4220de70",
  databaseURL: "https://payer-app-bc37e-default-rtdb.firebaseio.com",
} as FirebaseOptions;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseDatabase = getDatabase(app);
