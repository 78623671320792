import "./styles.css";
import { IonIcon } from "@ionic/react";
import { checkboxOutline, timeOutline, trashBinOutline } from "ionicons/icons";
import {
  LeadingActions,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import { ILoansInRoute } from "../../pages/loans/types";
import { confirmAlert } from "react-confirm-alert";
import MLoansSwipeLeading from "../molecules/m-loans-swipe-leading";

const loansLeadingActions = (
  loan: ILoansInRoute,
  queryDate: string,
  isPending: boolean = false,
  handleOnClickConfirm: (loanId: number, amount: number) => void
) => {
  const handleSwipeConfirmPayment = () => {
    if (!isPending) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <MLoansSwipeLeading
              queryDate={queryDate}
              loan={loan}
              onConfirm={handleOnClickConfirm}
              onClose={onClose}
            />
          );
        },
      });
    } else {
      confirmAlert({
        title: "Confirmar",
        message:
          "La confirmación ingresará el préstamo al listado de la ruta, ¿Desea continuar?",
        buttons: [
          {
            label: "Confirmar",
            onClick: () => handleOnClickConfirm(loan.id, 0),
          },
          {
            label: "Cancelar",
          },
        ],
      });
    }
  };

  return (
    <LeadingActions>
      <SwipeAction onClick={handleSwipeConfirmPayment}>
        <div className="swipe__item">
          <div className="swipe__item-icon">
            <IonIcon icon={checkboxOutline} size="large" />
          </div>
          <span className="swipe__item-text">
            {isPending ? "Confirmar" : "Abonar"}
          </span>
        </div>
      </SwipeAction>
    </LeadingActions>
  );
};

const loansTrailingActions = (
  loan: ILoansInRoute,
  handleOnClick: (loandId: number) => void,
  isPending: boolean = false
) => {
  const _onClick = () => {
    if (!isPending) {
      handleOnClick(loan.id);
    } else {
      confirmAlert({
        title: "Confirmar",
        message:
          "La confirmación rechazará el préstamo y lo eliminará de la ruta, ¿Desea continuar?",
        buttons: [
          {
            label: "Confirmar",
            onClick: () => handleOnClick(loan.id),
          },
          {
            label: "Cancelar",
          },
        ],
      });
    }
  };

  return (
    <TrailingActions>
      <SwipeAction onClick={_onClick}>
        <div className="swipe__item">
          <div className="swipe__item-icon">
            {isPending ? (
              <IonIcon icon={trashBinOutline} size="large" />
            ) : (
              <IonIcon icon={timeOutline} size="large" />
            )}
          </div>
          <span className="swipe__item-text">
            {isPending ? "Rechazar" : "Re Agendar"}
          </span>
        </div>
      </SwipeAction>
    </TrailingActions>
  );
};

export { loansLeadingActions, loansTrailingActions };
