import { get, post, put } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";
import { ICreateExpense } from "../../loans/types";
import {
  ICreateInjection,
  ICreateRoute,
  ICreateWithdraw,
  IEditRoute,
  IRemove,
} from "../types";

export const getRoutes = async (params: IServiceParams): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "routes/list",
      params,
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getExpensesAndWithdraw = async (
  routeId: number,
  date: string | null | undefined,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "dashboard/expenses-and-withdraw",
      params: {
        routeId,
        date,
        ...params,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getRoute = async (id: number): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: `routes/${id}/detail`,
      params: {},
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createRoute = async (body: ICreateRoute): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "routes/create",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const editRoute = async (
  id: number,
  body: IEditRoute
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `routes/edit/${id}`,
      id,
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const deleteRoute = async (id: number): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: "routes/delete",
      id,
      body: {},
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const createExpense = async (
  body: ICreateExpense
): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "expenses/create",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const createInjection = async (
  body: ICreateInjection
): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "injections/create",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const createWithdraw = async (
  body: ICreateWithdraw
): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "withdrawls/create",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const getInjections = async (
  routeId: number,
  date: string | null | undefined,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "injections/list",
      params: {
        routeId,
        date,
        ...params,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const removeExpense = async (
  id: number,
  payload: IRemove
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `expenses/${id}/delete`,
      id,
      body: payload,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const removeWithdraw = async (
  id: number,
  payload: IRemove
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `withdrawls/${id}/delete`,
      id,
      body: payload,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const removeInjection = async (
  id: number,
  payload: IRemove
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `injections/${id}/delete`,
      id,
      body: payload,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};
