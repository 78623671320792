import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PREFIX_AUTH, authenticate } from "../../pages/login/services/auth";
import { setMessage } from "../messages";
import { IFormLogin } from "../../pages/login/types/IFormLogin";

const user = JSON.parse(localStorage.getItem(`${PREFIX_AUTH}-data`) || "{}");
const token = localStorage.getItem(`${PREFIX_AUTH}-token`);
const route = localStorage.getItem(`${PREFIX_AUTH}-route`);
const abilities = localStorage.getItem(`${PREFIX_AUTH}-abilities`);

const initialState =
  user?.userId && token && route && abilities
    ? { isLoggedIn: true, user, token, abilities }
    : { isLoggedIn: false, user: null, token: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = false;
    });
  },
});

export const login = createAsyncThunk(
  "auth/login",
  async (input: IFormLogin, thunkAPI) => {
    try {
      const user = await authenticate({ ...input });
      return user;
    } catch (error: any) {
      thunkAPI.dispatch(setMessage(error.message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export default authSlice.reducer;
