import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  INotificationDataLoanConfirmation,
  INotificationStatus,
} from "../types";
import { setNotificationDataDetail } from "../../../slices/notifications";
import { IResponse } from "../../../services/types";
import { put } from "../../../services/api";

export const setDataDetail = createAsyncThunk(
  "notifications/detail",
  async (props: INotificationDataLoanConfirmation, thunkAPI) => {
    thunkAPI.dispatch(setNotificationDataDetail(props));
  }
);

export const notifyStatus = async (
  id: number,
  body: INotificationStatus
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: "loans/confirm-or-reject",
      id,
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};
