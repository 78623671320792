/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import currencyMoney from "../../utils/currency-money";
import { confirmAlert } from "react-confirm-alert";
import OInjectionModal from "./o-injection-modal";
import { useSelector } from "react-redux";
import { IInputOAdminCardBalance } from "../types/IInputsComponents";
import { can } from "../../utils/security";

const OAdminCardBalance = (props: IInputOAdminCardBalance) => {
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const handleOnClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <OInjectionModal routeId={defaultRoute.id} queryDate={queryDate} onClose={onClose} />;
      },
    });
  };

  return (
    <div className="balance">
      <div className="left">
        <span className="title">Saldo Total</span>
        <h1 className="total">{currencyMoney(props.balance || 0)}</h1>
      </div>
      <div className="right">
        {can('api-injections-create_injection') && (
          <a className="button" onClick={handleOnClick}>
            <IonIcon icon={addOutline} />
          </a>
        )}
      </div>
    </div>
  );
};

export default OAdminCardBalance;
