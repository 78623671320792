import React from "react";
import { IInputMAdminCardSummaryInfo } from "../types/IInputsComponents";
import currencyMoney from "../../utils/currency-money";

const MAdminCardSummaryInfo = (input: IInputMAdminCardSummaryInfo) => {
  const htmlClass = `value ${input.textType}`;

  return (
    <div className="col-6" onClick={input.onClick}>
      <div className="stat-box">
        <div className="title">{input.text}</div>
        <div className={htmlClass}>{currencyMoney(input.amount || 0)}</div>
      </div>
    </div>
  );
};

export default MAdminCardSummaryInfo;
