import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationDataDetail: (state, action) => {
      return { notifications: action.payload };
    },
  },
});

const { reducer, actions } = notificationsSlice;

export const { setNotificationDataDetail } = actions;
export default reducer;
