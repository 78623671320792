import React, { useEffect, useState } from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { useForm, SubmitHandler } from "react-hook-form";
import { IFormLogin } from "./types/IFormLogin";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import ALogo from "../../components/atoms/a-logo";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../slices/messages";
import { login } from "../../slices/auth";
import OSelectRouteModal from "../../components/organisms/o-select-route-modal";
import { getRoutes } from "../routes/services";
import { DEFAULT_LIMIT } from "../../utils/constants";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [routes, setRoutes] = useState([]);

  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const { message } = useSelector((state: any) => state.message);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormLogin>();

  const onSubmit: SubmitHandler<IFormLogin> = async (input) => {
    dispatch(login(input) as any)
      .unwrap()
      .then(async () => {
        const { data } = await getRoutes({
          filter: "",
          page: 1,
          limit: DEFAULT_LIMIT,
        });
        setRoutes(data.items);
        setIsOpen(true);
      })
      .catch((error: any) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  if (isLoggedIn && defaultRoute?.id) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <OSelectRouteModal isOpen={isOpen} routes={routes} />

      <div id="appCapsule" className="login-page">
        <ALogo />
        <div className="section mt-2 text-center">
          <h4>Ingresa tus credenciales</h4>
        </div>
        <div className="section mb-5 p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body pb-1">
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="email1">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email1"
                      autoComplete="off"
                      placeholder="Tu correo"
                      {...register("email", { required: true })}
                    />
                    <i className="clear-input">
                      <IonIcon icon={closeCircle} />
                    </i>
                  </div>
                  {errors.email && <AFieldErrorMessage />}
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="password1">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password1"
                      autoComplete="off"
                      placeholder="Tu contraseña"
                      {...register("password", { required: true })}
                    />
                    <i className="clear-input">
                      <IonIcon icon={closeCircle} />
                    </i>
                  </div>
                  {errors.password && <AFieldErrorMessage />}
                </div>
              </div>
            </div>

            <div className="form-button-group transparent">
              <button type="submit" className="btn btn-dark btn-block btn-lg">
                Iniciar Sesión
              </button>
            </div>
          </form>

          {message && (
            <div className="alert alert-outline-danger mt-3" role="alert">
              {message}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
