/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import OHeaderListActionPage from "../../../components/organisms/o-header-list-action-page";
import { closeOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPaymentsHistory } from "../services";
import MLoadMore from "../../../components/molecules/m-load-more";
import dayjs from "dayjs";
import currencyMoney from "../../../utils/currency-money";

const PaymentsHistoryReportPage = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [list, setList] = useState([]);

  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );

  const searchPayments = async (page: number) => {
    try {
      const { data } = await getPaymentsHistory(defaultRoute.id, {
        filter,
        page,
        limit: 50,
      });

      setList(page === 1 ? data.items : [...list, ...data.items]);
      setPagination(data.meta);
    } catch (error: any) {
      console.error(error.message);
    }
  };
  const loadMore = (page: number) => {
    searchPayments(page);
  };

  const handleActionPage = () => {
    navigate("/reports");
  };

  useEffect(() => {
    searchPayments(1);
  }, [filter]);

  return (
    <>
      <OHeaderListActionPage
        title="Consultar"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        <div className="card">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Fecha</th>
                  <th scope="col" className="text-end">
                    Abono
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((item: any, index: number) => {
                  return (
                    <tr key={`item-${index}`}>
                      <th scope="row">{item.customerName}</th>
                      <td>{dayjs(item.paymentDate).format("MMM DD, YYYY")}</td>
                      <td className="text-end text-primary">
                        {currencyMoney(item.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <MLoadMore
        currentPage={pagination.currentPage}
        totalPages={pagination.lastPage}
        dispatch={loadMore}
      />
    </>
  );
};

export default PaymentsHistoryReportPage;
