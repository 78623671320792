import { ToastOptions, toast } from "react-toastify";

const defaultOptions = {
  position: "top-right",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
} as ToastOptions;

export const alertError = (message: string) => {
  toast.error(message, defaultOptions);
};

export const alertSuccess = (message: string, autoClose: number = 7000) => {
  defaultOptions.autoClose = autoClose;
  toast.success(message, defaultOptions);
};
