import { MULTIPLICATOR } from "./constants";

const currencyMoney = (amount: number): string => {
  if (amount === null) return '0';

  return (amount / MULTIPLICATOR).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export default currencyMoney;
