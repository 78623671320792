import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardPage from "../../pages/dashboard";
import UsersPage from "../../pages/users";
import RoutesPage from "../../pages/routes";
import SidebarLayout from "../sidebar";
import CreateUserPage from "../../pages/users/create";
import { ToastContainer } from "react-toastify";
import EditUserPage from "../../pages/users/edit";
import LoginPage from "../../pages/login";
import CreateRoutePage from "../../pages/routes/create";
import EditRoutePage from "../../pages/routes/edit";
import LoansPage from "../../pages/loans";
import CreateLoansPage from "../../pages/loans/create";
import CreateCollectionPage from "../../pages/collections/create";
import NotificationsPage from "../../pages/notifications";
import LoanConfirmationPage from "../../pages/notifications/loan-confirmation";
import CollectionsPage from "../../pages/collections";
import ToSecuencePage from "../../pages/loans/to-secuence";
import PaymentsPage from "../../pages/loans/payments";
import LoansApprovedPage from "../../pages/loans/loans-approved";
import ReportsPage from "../../pages/reports";
import PaymentsHistoryReportPage from "../../pages/reports/customers/payments-history";
import OPaymentsHistory from "../../components/organisms/o-payments-history";
import ExpensesAndWithdrawPage from "../../pages/routes/expenses-and-withdraw";
import InjectionsPage from "../../pages/routes/injections";

const RootLayout = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <SidebarLayout />

        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/auth" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/create" element={<CreateUserPage />} />
          <Route path="/users/edit/:id" element={<EditUserPage />} />
          <Route path="/routes" element={<RoutesPage />} />
          <Route path="/routes/create" element={<CreateRoutePage />} />
          <Route path="/routes/edit" element={<EditRoutePage />} />
          <Route path="/loans" element={<LoansPage />} />
          <Route path="/loans/create/:id" element={<CreateLoansPage />} />
          <Route path="/loans/payments" element={<PaymentsPage />} />
          <Route path="/loans/approved" element={<LoansApprovedPage />} />
          <Route
            path="/routes/expenses-and-withdraw"
            element={<ExpensesAndWithdrawPage />}
          />
          <Route path="/routes/injections" element={<InjectionsPage />} />
          <Route
            path="/loans/:routeId/history/:loanId"
            element={<OPaymentsHistory />}
          />
          <Route path="/collections" element={<CollectionsPage />} />
          <Route
            path="/collections/create"
            element={<CreateCollectionPage />}
          />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route
            path="/notifications/loan-confirmation"
            element={<LoanConfirmationPage />}
          />
          <Route path="/to-secuence" element={<ToSecuencePage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route
            path="/reports/customers/payments-history"
            element={<PaymentsHistoryReportPage />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default RootLayout;
