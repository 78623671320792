/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getDashboardLoans } from "./services";
import MLoadMore from "../../components/molecules/m-load-more";
import { IDashboardLons, ILoansApprovedProps } from "./types";
import { closeOutline } from "ionicons/icons";
import OHeaderListActionPage from "../../components/organisms/o-header-list-action-page";
import currencyMoney from "../../utils/currency-money";
import dayjs from "dayjs";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { DEFAULT_LIMIT } from "../../utils/constants";

const LoansApprovedPage = (props: ILoansApprovedProps) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [loans, setLoans] = useState([]);

  const getListLoansApproved = async (page: number) => {
    const response = await getDashboardLoans(
      defaultRoute.id,
      props.date ?? queryDate,
      {
        filter,
        page,
        limit: DEFAULT_LIMIT,
      }
    );
    setLoans(
      page === 1 ? response.data.items : [...loans, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getListLoansApproved(page);
  };
  const handleActionPage = async () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    getListLoansApproved(1);
  }, [filter]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListActionPage
        title="Préstamos"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
        isShowIcon={props.isShowIcon}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!loans.length && <ADataEmpty />}

        {loans.map((loan: IDashboardLons) => {
          return (
            <div className="card-custom-container">
              <div className="card card-border">
                <div className="card-body">
                  <h5 className="card-title">
                    <span className="badge badge-primary">{loan.position}</span>
                    {loan.customerName}
                  </h5>
                  <p className="card-text">
                    <div>
                      <div className="card-custom-item">
                        <span>Crédito</span>
                        <span>{currencyMoney(loan.credit)}</span>
                      </div>
                      <div className="card-custom-item">
                        <span>Días</span>
                        <span>{loan.days}</span>
                      </div>
                      <div className="card-custom-item">
                        <span>Cuota</span>
                        <span>{currencyMoney(loan.fee)}</span>
                      </div>
                      <div className="card-custom-item">
                        <span>Total</span>
                        <span>{currencyMoney(loan.return)}</span>
                      </div>
                      <div className="card-custom-item">
                        <span>Fecha de salida</span>
                        <span>
                          {loan.endDate}
                        </span>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          );
        })}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

LoansApprovedPage.defaultProps = {
  isShowIcon: true,
};

export default LoansApprovedPage;
