import React from "react";
import { IInputOMoreInfoLoanModal } from "../types/IInputsComponents";
import dayjs from "dayjs";
import currencyMoney from "../../utils/currency-money";

const OMoreInfoLoanModal = (props: IInputOMoreInfoLoanModal) => {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Información</h5>
          <div className="card-text">
            <div className="card-custom-item">
              <span>Crédito</span>
              <span>{currencyMoney(props.loan.credit)}</span>
            </div>
            <div className="card-custom-item">
              <span>Devolver</span>
              <span>{currencyMoney(props.loan.return)}</span>
            </div>
            <div className="card-custom-item">
              <span>Días</span>
              <span>{props.loan.days}</span>
            </div>
            <div className="card-custom-item">
              <span>Saldo</span>
              <span>{currencyMoney(props.loan.return - props.loan.paid)}</span>
            </div>
            <div className="card-custom-item">
              <span>Inicia</span>
              <span>{dayjs(props.loan.beginDate).format("MMM DD, YYYY")}</span>
            </div>
            <div className="card-custom-item">
              <span>Finaliza</span>
              <span>
                {dayjs(props.loan.beginDate)
                  .add(props.loan.days, "day")
                  .format("MMM DD, YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="btn-group d-flex">
            <button onClick={props.onClose} className="btn btn-secondary">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OMoreInfoLoanModal;
