import React from "react";
import { IInputOHeaderBackSave } from "../types/IInputsComponents";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline, checkmarkOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";

const OHeaderBackSave = (input: IInputOHeaderBackSave) => {
  const navigate = useNavigate();

  const handleOnClickBack = () => {
    navigate(input.pathToBack);
  };
  const handleOnClickSave = () => {
    input.onSave();
  };

  return (
    <div className="appHeader o-header-back-save">
      <div className="left">
        <button onClick={handleOnClickBack} className="headerButton">
          <IonIcon className="ion-icon" icon={arrowBackOutline} />
        </button>
      </div>
      <div className="title">{input.title}</div>
      <div className="right">
        <button onClick={handleOnClickSave} className="headerButton">
          <IonIcon className="ion-icon" icon={checkmarkOutline} />
        </button>
      </div>
    </div>
  );
};

export default OHeaderBackSave;
