/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import OHeaderListPage from "../../components/organisms/o-header-list-page";
import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  ellipsisHorizontalOutline,
  pencilOutline,
  walletOutline,
} from "ionicons/icons";
import currencyMoney from "../../utils/currency-money";
import { deleteRoute, getRoutes } from "./services";
import { IRouteList } from "./types";
import MLoadMore from "../../components/molecules/m-load-more";
import { confirmAlert } from "react-confirm-alert";
import ADataEmpty from "../../components/atoms/a-data-empty";

const RoutesPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  const [filter, setFilter] = useState("");
  const [routes, setRoutes] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });

  const getRoutesList = async (page: number) => {
    const response = await getRoutes({ filter, page, limit: 50 });
    setRoutes(
      page === 1 ? response.data.items : [...routes, ...response.data.items]
    );
    setPagination(response.data.meta);
  };
  const deleteLogicRoute = async (id: number) => {
    await deleteRoute(id);
    getRoutesList(1);
  };

  const handleOnClickEdit = (id: number) => {
    navigate(`/routes/edit/${id}`);
  };
  const handleOnClickDelete = (id: number) => {
    confirmAlert({
      title: "Confirmar",
      message:
        "Si elimina la ruta no podrá visualizar sus datos, ¿desea continuar?",
      buttons: [
        {
          label: "Continuar",
          onClick: () => deleteLogicRoute(id),
        },
        {
          label: "Cancelar",
          onClick: () => null,
        },
      ],
    });
  };
  const handleOnClickViewLoans = (id: number) => {
    navigate(`/loans/${id}`);
  };
  const loadMore = (page: number) => {
    getRoutesList(page);
  };

  useEffect(() => {
    getRoutesList(1);
  }, [filter]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListPage
        path="/routes/create"
        title="Rutas"
        filter={filter}
        dispatch={setFilter}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!routes.length && <ADataEmpty />}

        {routes.length && (
          <div className="section mt-2">
            {routes.map((route: IRouteList, index: number) => {
              return (
                <div
                  className="card custom-card"
                  key={`route-${index}-${route.id}`}
                >
                  <div className="card-header">
                    <div className="left">Ruta {route.name}</div>
                    <div className="right">
                      <button
                        type="button"
                        className="btn btn-link btn-icon"
                        data-bs-toggle="dropdown"
                      >
                        <IonIcon
                          size="large"
                          icon={ellipsisHorizontalOutline}
                        />
                      </button>
                      <div className="dropdown-menu dropdown-menu-end">
                        <button
                          className="dropdown-item"
                          onClick={() => handleOnClickViewLoans(route.id)}
                        >
                          <IonIcon icon={walletOutline} />{" "}
                          <span className="ml-1">Cobros</span>
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => handleOnClickEdit(route.id)}
                        >
                          <IonIcon icon={pencilOutline} />{" "}
                          <span className="ml-1">Editar</span>
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => handleOnClickDelete(route.id)}
                        >
                          <IonIcon icon={closeOutline} />{" "}
                          <span className="ml-1">Eliminar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      <ul className="listview flush transparent">
                        <li className="item">
                          <strong>Cobrador:</strong> {route.collectorName}
                        </li>
                        <li className="item">
                          <strong>Base del día:</strong>{" "}
                          {currencyMoney(route.baseAmount)}
                        </li>
                        <li className="item">
                          <strong>Valor de la ruta:</strong>{" "}
                          {currencyMoney(route.loans)}
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

export default RoutesPage;
