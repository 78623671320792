/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import OHeader from "../../components/organisms/o-header";
import OAdminCardBalance from "../../components/organisms/o-admin-card-balance";
import OAdminCardFooter from "../../components/organisms/o-admin-card-footer";
import OAdminCardSummaryInfo from "../../components/organisms/o-admin-card-summary-info";
import { IDashBalance, IDashProgressRoute } from "./types";
import { useSelector } from "react-redux";
import { getBalance, getProgressRoute } from "./services/dash";
import OProgressRouteDashboard from "../../components/organisms/o-progress-route-dashboard";

const AdminDashboardPage = () => {
  const [balance, setBalance] = useState({} as IDashBalance);
  const [progressRoute, setProgressRoute] = useState(
    [] as Array<IDashProgressRoute>
  );

  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const syncDashBalance = async (routeId: number) => {
    const [{ data: responseBalance }, { data: responseProgressRoute }] =
      await Promise.all([
        getBalance(routeId, queryDate),
        getProgressRoute(routeId, queryDate),
      ]);

    setBalance(responseBalance.items[0]);
    setProgressRoute(responseProgressRoute.items);
  };

  useEffect(() => {
    syncDashBalance(defaultRoute.id);
  }, []);

  return (
    <>
      <OHeader />

      <div id="appCapsule">
        <div className="section wallet-card-section pt-1">
          <div className="wallet-card">
            <OAdminCardBalance balance={balance?.balance} />
            <OAdminCardFooter />
          </div>
        </div>

        <div className="section">
          <OAdminCardSummaryInfo {...balance} />
        </div>

        <div className="section mt-4">
          <OProgressRouteDashboard items={progressRoute} />
        </div>
      </div>
    </>
  );
};

export default AdminDashboardPage;
