import React, { useRef } from "react";
import "./styles.css";
import { IInputMLoansSwipeLeading } from "../types/IInputsComponents";
import { ICreatePayment } from "../../pages/loans/types";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import AFieldErrorMessage from "../atoms/a-field-error-message";
import { MULTIPLICATOR } from "../../utils/constants";
import { useSelector } from "react-redux";

const MLoansSwipeLeading = (props: IInputMLoansSwipeLeading) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreatePayment>();

  const onSubmit: SubmitHandler<ICreatePayment> = async (input) => {
    props.onConfirm(props.loan.id, input.amount);
    props.onClose();
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Confirmar Abono</h5>
        <div className="card-text">
          <form onSubmit={handleSubmit(onSubmit)}>
            <button
              ref={buttonRef}
              type="submit"
              id="submit"
              style={{ display: "none" }}
            />
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="date">
                  Fecha del abono
                </label>
                <input
                  {...register("date", { required: true })}
                  type="date"
                  className="form-control"
                  id="date"
                  min={dayjs().subtract(2, "day").format("YYYY-MM-DD")}
                  max={props.queryDate}
                  defaultValue={props.queryDate}
                  readOnly={true}
                />
              </div>
              {errors.date && <AFieldErrorMessage />}
            </div>

            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="amount">
                  Valor
                </label>
                <input
                  {...register("amount", { required: true })}
                  type="number"
                  className="form-control"
                  id="amount"
                  autoComplete="off"
                  placeholder="* 1000"
                  defaultValue={props.loan.fee / MULTIPLICATOR}
                />
              </div>
              {errors.amount && <AFieldErrorMessage />}
            </div>
          </form>
        </div>
      </div>
      <div className="card-footer">
        <div className="btn-group d-flex">
          <button onClick={props.onClose} className="btn btn-secondary">
            Cancelar
          </button>
          <button onClick={handleOnSave} className="btn btn-success">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default MLoansSwipeLeading;
