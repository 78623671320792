/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import currencyMoney from "../../utils/currency-money";
import { SubmitHandler, useForm } from "react-hook-form";
import { ICreateCollection, IResumeCollection } from "./types";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import dayjs from "dayjs";
import { createCollection, getResumeToCollect } from "./services";
import { useSelector } from "react-redux";
import { alertError, alertSuccess } from "../../utils/alerts";
import { useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { readerOutline } from "ionicons/icons";
import ExpensesAndWithdrawPage from "../routes/expenses-and-withdraw";
import Modal from "../../components/organisms/o-modal";
import PaymentsPage from "../loans/payments";
import LoansApprovedPage from "../loans/loans-approved";
import InjectionsPage from "../routes/injections";

const CreateCollectionPage = () => {
  const navigate = useNavigate();
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [collectionDate, setCollectionDate] = useState(queryDate);
  const [resumeData, setResumeData] = useState({} as IResumeCollection);
  const [isExpenseOrWithdrawShown, setIsExpenseOrWithdrawShown] =
    useState(false);
  const [isInjectionShown, setIsInjectionShown] = useState(false);
  const [isPaymentShown, setIsPaymentShown] = useState(false);
  const [isLoansApprovedShown, setIsLoansApprovedShown] = useState(false);

  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );

  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateCollection>();

  const getResume = async () => {
    if (!collectionDate) return;

    const { status, data, message } = await getResumeToCollect(
      defaultRoute.id,
      collectionDate
    );

    if (!status) {
      alertError(message);
    }

    setResumeData(data.items[0]);
  };

  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };
  const handleOnChangeDate = (event: any) => {
    setCollectionDate(event.target.value);
  };
  const handleOnClickLoansApproved = () => {
    setIsLoansApprovedShown(true);
  };
  const handleOnClickExpenseOrWithdraw = () => {
    setIsExpenseOrWithdrawShown(true);
  };
  const handleOnClickPayment = () => {
    setIsPaymentShown(true);
  };
  const handleOnClickInjection = () => {
    setIsInjectionShown(true);
  };
  const onSubmit: SubmitHandler<ICreateCollection> = async (input) => {
    const { status, message } = await createCollection({
      ...input,
      ...resumeData,
      routeId: defaultRoute.id,
    });

    if (status === true) {
      alertSuccess(message);
      navigate("/collections");
    } else {
      alertError(message);
    }
  };

  useEffect(() => {
    getResume();
  }, [collectionDate]);

  return (
    <>
      <OHeaderBackSave
        title="Liquidar Ruta"
        pathToBack="/collections"
        onSave={handleOnSave}
      />

      {isInjectionShown && (
        <Modal close={() => setIsInjectionShown(false)}>
          <InjectionsPage isShowIcon={false} date={collectionDate} />
        </Modal>
      )}

      {isExpenseOrWithdrawShown && (
        <Modal close={() => setIsExpenseOrWithdrawShown(false)}>
          <ExpensesAndWithdrawPage isShowIcon={false} date={collectionDate} />
        </Modal>
      )}

      {isPaymentShown && (
        <Modal close={() => setIsPaymentShown(false)}>
          <PaymentsPage isShowIcon={false} date={collectionDate} />
        </Modal>
      )}

      {isLoansApprovedShown && (
        <Modal close={() => setIsLoansApprovedShown(false)}>
          <LoansApprovedPage isShowIcon={false} date={collectionDate} />
        </Modal>
      )}

      {!isExpenseOrWithdrawShown &&
        !isPaymentShown &&
        !isInjectionShown &&
        !isLoansApprovedShown && (
          <div id="appCapsule" className="full-height">
            <div className="section mt-2 mb-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <button
                  ref={buttonRef}
                  type="submit"
                  id="submit"
                  style={{ display: "none" }}
                />

                <ul className="listview flush transparent simple-listview no-space mt-3">
                  <li>
                    <strong>Inyección</strong>
                    <span className="text-success with-icon">
                      {currencyMoney(resumeData.injections)}
                      <IonIcon
                        icon={readerOutline}
                        onClick={handleOnClickInjection}
                      />
                    </span>
                  </li>
                  <li>
                    <strong>Recaudo</strong>
                    <span className="text-success with-icon">
                      {currencyMoney(resumeData.payments)}
                      <IonIcon
                        icon={readerOutline}
                        onClick={handleOnClickPayment}
                      />
                    </span>
                  </li>
                  <li>
                    <strong>Préstamo</strong>
                    <span className="text-warning with-icon">
                      {currencyMoney(resumeData.loans)}
                      <IonIcon
                        icon={readerOutline}
                        onClick={handleOnClickLoansApproved}
                      />
                    </span>
                  </li>
                  <li>
                    <strong>Gasto</strong>
                    <span className="text-danger with-icon">
                      {currencyMoney(resumeData.expenses)}
                      <IonIcon
                        icon={readerOutline}
                        onClick={handleOnClickExpenseOrWithdraw}
                      />
                    </span>
                  </li>
                  <li>
                    <strong>Retiro</strong>
                    <span className="text-danger with-icon">
                      {currencyMoney(resumeData.withdrawls)}
                      <IonIcon
                        icon={readerOutline}
                        onClick={handleOnClickExpenseOrWithdraw}
                      />
                    </span>
                  </li>
                  <li>
                    <strong>Total a entregar</strong>
                    <h3 className="m-0">{currencyMoney(resumeData.balance)}</h3>
                  </li>
                </ul>

                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="date">
                      Fecha
                    </label>
                    <input
                      {...register("date", { required: true })}
                      type="date"
                      className="form-control"
                      id="date"
                      min={dayjs().subtract(2, "day").format("YYYY-MM-DD")}
                      max={collectionDate}
                      defaultValue={collectionDate}
                      readOnly={true}
                      onChange={handleOnChangeDate}
                    />
                  </div>
                  {errors.date && (
                    <AFieldErrorMessage text={errors.date.message} />
                  )}
                </div>

                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="amountDelivered">
                      Valor entregado
                    </label>
                    <input
                      {...register("amountDelivered", {
                        required: true,
                      })}
                      type="number"
                      className="form-control"
                      id="amountDelivered"
                      placeholder="* 1000"
                      autoFocus
                    />
                  </div>
                  {errors.amountDelivered && <AFieldErrorMessage />}
                </div>
              </form>
            </div>
          </div>
        )}
    </>
  );
};

export default CreateCollectionPage;
