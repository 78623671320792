/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline, walletOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import { firebaseDatabase } from "../../config/firebase.config";
import { onValue, ref, update } from "firebase/database";
import dayjs from "dayjs";
import { INotification } from "./types";
import { LOAN_STATUSES } from "../loans/enums";
import { useDispatch, useSelector } from "react-redux";
import { setDataDetail } from "./services";
import ADataEmpty from "../../components/atoms/a-data-empty";

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState([] as Array<INotification>);
  const { user } = useSelector((state: any) => state.auth);

  const readed = (key: string) => {
    const db = ref(firebaseDatabase, "notifications");
    const data: any = {};
    data[key + "/isRead"] = true;
    update(db, data);
  };
  const getTextStatus = (notification: INotification) => {
    switch (notification.data?.status) {
      case LOAN_STATUSES.PENDING_APPROVAL:
        return "Pendiente";
      case LOAN_STATUSES.CONFIRMED:
        return "Confirmado";
      default:
        return null;
    }
  };
  const getClassStatus = (notification: INotification) => {
    switch (notification.data?.status) {
      case LOAN_STATUSES.PENDING_APPROVAL:
        return "badge badge-warning";
      case LOAN_STATUSES.CONFIRMED:
        return "badge badge-success";
      default:
        return undefined;
    }
  };
  const handleOnClickMenu = () => {
    navigate("/dashboard");
  };
  const handleOnClickDetail = async (notification: INotification) => {
    if (
      notification.data.status === LOAN_STATUSES.CONFIRMED ||
      !JSON.parse(notification.whoRead).includes(user.roleId)
    ) {
      return false;
    }

    switch (notification.type) {
      case "loans.confirmation":
        dispatch(setDataDetail(notification.data) as any)
          .unwrap()
          .then(() => {
            readed(notification.data.key);
            navigate("/notifications/loan-confirmation");
          })
          .catch((error: any) => console.error(error));
        break;
    }
  };

  useEffect(() => {
    const db = ref(firebaseDatabase, "notifications");
    return onValue(db, (snapshot) => {
      const snapshotData = snapshot.val();

      if (snapshot.exists()) {
        const keys = Object.keys(snapshotData);
        const values: any[] = Object.values(snapshotData);
        const data: INotification[] = values.map((item: any, index: number) => {
          const key = keys[index];

          return {
            ...item,
            data: {
              ...item.data,
              key,
            },
          };
        });
        setList(
          data
            .filter((f) => Number(user.lenderId) === Number(f?.data?.lenderId))
            .reverse()
        );
      }
    });
  }, []);

  return (
    <>
      <div className="appHeader o-header-list">
        <div className="left">
          <button onClick={handleOnClickMenu} className="headerButton">
            <IonIcon className="ion-icon" icon={arrowBackOutline} />
          </button>
        </div>
        <div className="pageTitle">Notificaciones</div>
      </div>
      <div id="appCapsule">
        <div className="section full">
          {!list.length && <ADataEmpty />}

          {list.length > 0 && (
            <ul className="listview image-listview flush">
              {list.map((notification: INotification, index: number) => {
                return (
                  <li
                    className={notification.isRead ? "" : "active"}
                    key={`notification-${index}`}
                    onClick={() => handleOnClickDetail(notification)}
                  >
                    <a className="item">
                      <div className="icon-box bg-primary">
                        <IonIcon icon={walletOutline} />
                      </div>
                      <div className="in">
                        <div>
                          <div className="mb-05">
                            {getTextStatus(notification) != null && (
                              <>
                                <span className={getClassStatus(notification)}>
                                  {getTextStatus(notification)}
                                </span>
                                <br />
                              </>
                            )}
                            <strong>{notification.title}</strong>
                          </div>
                          <div className="text-small mb-05">
                            {notification.description}
                          </div>
                          <div className="text-xsmall">
                            {dayjs(notification.createdAt).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </div>
                        </div>
                        {!notification.isRead && (
                          <span className="badge badge-primary badge-empty"></span>
                        )}
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationsPage;
