import React, { MouseEventHandler, ReactElement } from "react";
import "./styles.css";

interface BackgroundI {
  onClick: MouseEventHandler<HTMLDivElement>;
  children: any;
}

const Background = ({
  children,
  onClick,
}: BackgroundI): ReactElement<BackgroundI, "modal-background"> => (
  <div className="background" onClick={onClick}>
    {children}
  </div>
);

const ClickCapturer = (props: any) => {
  const { onClick, children } = props;
  return (
    <div className="click-capturer" onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={onClick} />
      {children}
    </div>
  );
};

const CloseButton = (props: any) => {
  const { onClick } = props;
  return (
    <button type="button" onClick={onClick} className="close">
      {""}
    </button>
  );
};

interface ModalI {
  children: any;
  close: MouseEventHandler<HTMLDivElement>;
}

const Modal = ({ children, close }: ModalI) => {
  return (
    <Background onClick={close}>
      <ClickCapturer onClick={close}>{children}</ClickCapturer>
    </Background>
  );
};

export default Modal;
