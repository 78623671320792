/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { closeOutline, trashOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import currencyMoney from "../../utils/currency-money";
import dayjs from "dayjs";
import MLoadMore from "../../components/molecules/m-load-more";
import OHeaderListActionPage from "../../components/organisms/o-header-list-action-page";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { getExpensesAndWithdraw, removeExpense, removeWithdraw } from "./services";
import { IExpenseAndWithdraw, IExpenseAndWithdrawProps } from "./types";
import { useSelector } from "react-redux";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { can } from "../../utils/security";
import { IonIcon } from "@ionic/react";
import { alertError, alertSuccess } from "../../utils/alerts";

const ExpensesAndWithdrawPage = (props: IExpenseAndWithdrawProps) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [list, setList] = useState([]);

  const getList = async (page: number) => {
    const response = await getExpensesAndWithdraw(
      defaultRoute.id,
      props.date ?? queryDate,
      {
        filter,
        page,
        limit: DEFAULT_LIMIT,
      }
    );
    setList(
      page === 1 ? response.data.items : [...list, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getList(page);
  };
  const handleActionPage = async () => {
    navigate("/dashboard");
  };
  const handleOnClickDelete = async (id: number, type: string) => {
    let response: any;
    if (type === "expense") {
      response = await removeExpense(id, {
        routeId: defaultRoute.id,
        date: queryDate,
      });
    } else {
      response = await removeWithdraw(id, {
        routeId: defaultRoute.id,
        date: queryDate,
      });
    }

    if (response.status === true) {
      alertSuccess(response.message);
      getList(1);
    } else {
      alertError(response.message);
    }
  };

  useEffect(() => {
    getList(1);
  }, [filter]);

  return (
    <>
      <OHeaderListActionPage
        title="Egresos"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
        isShowIcon={props.isShowIcon}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!list.length && <ADataEmpty />}

        {list.length > 0 && (
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Comentario</th>
                <th>Usuario</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((item: IExpenseAndWithdraw, index: number) => {
                return (
                  <tr key={`item-${index}`}>
                    <td>
                      <span
                        className={
                          item.type === "expense"
                            ? "badge badge-danger mb-1"
                            : "badge badge-warning mb-1"
                        }
                      >
                        {item.type === "expense" ? "Gasto" : "Retiro"}
                      </span>
                      {item.comments}
                    </td>
                    <td>{item.collectorName}</td>
                    <td>{currencyMoney(item.amount)}</td>
                    <td>
                      {((item.type === "expense" &&
                        can("api-expenses-delete_expense")) ||
                        (item.type === "withdraw" &&
                          can("api-withdrawls-delete_withdraw"))) &&
                        item.date == queryDate && (
                          <IonIcon
                            onClick={() =>
                              handleOnClickDelete(item.id, item.type)
                            }
                            size="large"
                            icon={trashOutline}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>

      <MLoadMore
        currentPage={pagination.currentPage}
        totalPages={pagination.lastPage}
        dispatch={loadMore}
      />
    </>
  );
};

ExpensesAndWithdrawPage.defaultProps = {
  isShowIcon: true,
};

export default ExpensesAndWithdrawPage;
