/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import { menuOutline, notificationsOutline } from "ionicons/icons";
import { openDrawer } from "../../slices/drawer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../config/firebase.config";

const OHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const [countNotifications, setCountNotifications] = useState(0);

  const handleOnClickMenu = () => {
    dispatch(openDrawer(true));
  };
  const handleOnClickNotifications = () => {
    navigate("/notifications");
  };

  useEffect(() => {
    /*const query = ref(firebaseDatabase, "notifications");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        let count = 0;
        Object.values(data).forEach((notification: any) => {
          if (
            !notification?.isRead &&
            Number(user?.lenderId) === Number(notification?.data?.lenderId)
          ) {
            count++;
          }
        });

        setCountNotifications(count);
      }
    });*/
  }, []);

  return (
    <div className="appHeader bg-primary text-light o-header">
      <div className="left">
        <button onClick={handleOnClickMenu} className="headerButton">
          <IonIcon className="ion-icon" icon={menuOutline} />
        </button>
      </div>
      <div className="pageTitle">COPP</div>
      {/*<div className="right">
        <button onClick={handleOnClickNotifications} className="headerButton">
          <IonIcon className="ion-icon" icon={notificationsOutline} />
          {countNotifications > 0 && (
            <span className="badge badge-danger">{countNotifications}</span>
          )}
        </button>
      </div>*/}
    </div>
  );
};

export default OHeader;
