import React from "react";
import "./styles.css";
import { IInputAFieldErrorMessage } from "../../types/IInputsComponents";

const DEFAULT_MESSAGE = "Este campo es requerido";

const AFieldErrorMessage = (
  input: IInputAFieldErrorMessage = { text: DEFAULT_MESSAGE }
) => {
  return <div className="input-info input-info--red">{input?.text || DEFAULT_MESSAGE}</div>;
};

export default AFieldErrorMessage;
