/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import OHeaderListActionPage from "./o-header-list-action-page";
import { closeOutline } from "ionicons/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getPaymentsHistoryByLoan } from "../../pages/loans/services";
import MLoadMore from "../molecules/m-load-more";
import currencyMoney from "../../utils/currency-money";
import dayjs from "dayjs";
import { IPaymentsHistory } from "../../pages/loans/types";
import ADataEmpty from "../atoms/a-data-empty";
import { useSelector } from "react-redux";

const OPaymentsHistory = () => {
  const params = useParams()!;
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [payments, setPayments] = useState([]);
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const getPaymentsHistory = async (page: number) => {
    if (!params?.loanId) return navigate("/dashboard");

    const response = await getPaymentsHistoryByLoan(
      Number(params.loanId),
      queryDate,
      {
        filter,
        page,
        limit: 50,
      }
    );
    setPayments(
      page === 1 ? response.data.items : [...payments, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getPaymentsHistory(page);
  };
  const handleActionPage = async () => {
    if (!params?.routeId) return navigate("/dashboard");

    navigate(`/loans/${params.routeId}`);
  };

  useEffect(() => {
    getPaymentsHistory(1);
  }, [filter]);

  return (
    <>
      <OHeaderListActionPage
        title="Histórico"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
        isShowSearchBox={false}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!payments.length && <ADataEmpty />}

        {payments.length && <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Fecha</th>
                <th scope="col">Cuota</th>
                <th scope="col" className="text-end">
                  Valor
                </th>
              </tr>
            </thead>
            <tbody>
              {payments.map((item: IPaymentsHistory, index: number) => {
                return (
                  <tr key={`item-${index}`}>
                    <th scope="row">{item.customerName}</th>
                    <td>{dayjs(item.date).format("MMM DD, YYYY")}</td>
                    <td className="text-end text-primary">
                      {currencyMoney(item.fee)}
                    </td>
                    <td className="text-end text-primary">
                      {currencyMoney(item.amount)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>

      <MLoadMore
        currentPage={pagination.currentPage}
        totalPages={pagination.lastPage}
        dispatch={loadMore}
      />
    </>
  );
};

export default OPaymentsHistory;
