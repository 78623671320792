import React from "react";
import { IInputMloadMore } from "../types/IInputsComponents";

const MLoadMore = (input: IInputMloadMore) => {
  const handleOnClick = () => {
    input.dispatch(input.currentPage + 1);
  };

  return (
    <div className="section mt-3 mb-3">
      {input.currentPage < input.totalPages && (
        <button className="btn btn-block btn-secondary" onClick={handleOnClick}>
          Cargar más...
        </button>
      )}
    </div>
  );
};

export default MLoadMore;
