/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  getLoans,
  getLoansInRoute,
  reorderList,
  updatePosition,
} from "./services";
import MLoadMore from "../../components/molecules/m-load-more";
import { ILoansInRoute } from "./types";
import { refreshOutline } from "ionicons/icons";
import OHeaderListActionPage from "../../components/organisms/o-header-list-action-page";
import { alertError, alertSuccess } from "../../utils/alerts";
import currencyMoney from "../../utils/currency-money";
import { confirmAlert } from "react-confirm-alert";
import OChangePosition from "../../components/organisms/o-change-position";
import ADataEmpty from "../../components/atoms/a-data-empty";

const ToSecuencePage = () => {
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [loans, setLoans] = useState([]);

  const getLoansConfirmedInRoute = async (page: number) => {
    const response = await getLoans(defaultRoute.id, "all", queryDate, {
      filter,
      page,
      limit: 50,
    });
    setLoans(
      page === 1 ? response.data.items : [...loans, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getLoansConfirmedInRoute(page);
  };
  const handleActionPage = async () => {
    const { status, message } = await reorderList(defaultRoute.id);

    if (status) {
      alertSuccess(message);
      getLoansConfirmedInRoute(1);
    } else {
      alertError(message);
    }
  };
  const handleOnClickChangePosition = (loanId: number) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OChangePosition
            loanId={loanId}
            onSave={handleOnSave}
            onClose={onClose}
          />
        );
      },
    });
  };
  const handleOnSave = async (loanId: number, newPosition: number) => {
    const { status, message } = await updatePosition(
      loanId,
      defaultRoute.id,
      newPosition
    );

    if (status) {
      alertSuccess(message);
      getLoansConfirmedInRoute(1);
    } else {
      alertError(message);
    }
  };

  useEffect(() => {
    getLoansConfirmedInRoute(1);
  }, [filter]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListActionPage
        title="Secuenciar"
        filter={filter}
        dispatch={setFilter}
        icon={refreshOutline}
        handleAction={handleActionPage}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!loans.length && <ADataEmpty />}

        {loans.map((loan: ILoansInRoute) => {
          return (
            <ul className="listview image-listview">
              <li onClick={() => handleOnClickChangePosition(loan.id)}>
                <a className="item">
                  <div className="in">
                    <div>
                      <header>[ {loan.position} ]</header>
                      {loan.document}
                      <br />
                      {loan.customerName}
                      <br />
                      {loan.phone}
                      <br />
                      {loan.address}, {loan.neighborhood}
                      <footer>
                        <strong>Crédito:</strong> {" "}
                        {currencyMoney(loan.credit)}, {currencyMoney(loan.fee)}{" "}
                        x {loan.days}
                      </footer>
                    </div>
                    <span className="text-muted">Mover</span>
                  </div>
                </a>
              </li>
            </ul>
          );
        })}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

export default ToSecuencePage;
