import React from "react";
import { IonIcon } from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import currencyMoney from "../../utils/currency-money";
import { useSelector } from "react-redux";
import { notifyStatus } from "./services";
import { LOAN_STATUSES } from "../loans/enums";
import { alertError, alertSuccess } from "../../utils/alerts";
import { useNavigate } from "react-router-dom";

const LoanConfirmationPage = () => {
  const { notifications } = useSelector((state: any) => state.notifications);
  const navigate = useNavigate();

  const updateStatus = async (loanStatus: LOAN_STATUSES) => {
    const { status, message } = await notifyStatus(notifications.loanId, {
      routeId: notifications.routeId,
      firebaseKey: notifications.key,
      status: loanStatus,
    });

    if (status) {
      alertSuccess(message);
      navigate("/dashboard");
    } else {
      alertError(message);
    }
  };
  const handleOnConfirm = async () => {
    updateStatus(LOAN_STATUSES.CONFIRMED);
  };
  const handleOnReject = async () => {
    updateStatus(LOAN_STATUSES.CONFIRMED);
  };

  return (
    <>
      <OHeaderBackSave
        title="Detalle"
        pathToBack="/notifications"
        onSave={handleOnConfirm}
      />

      <div id="appCapsule" className="full-height">
        <div className="section">
          <div className="listed-detail mt-3">
            <div className="icon-wrapper">
              <div className="iconbox">
                <IonIcon icon={walletOutline} />
              </div>
            </div>
            <h3 className="text-center mt-2">Confirmar o Rechazar</h3>
          </div>

          <ul className="listview simple-listview no-space mt-3">
            <li>
              <span>Ruta</span>
              <strong>{notifications?.routeName}</strong>
            </li>
            <li>
              <span>Cliente</span>
              <strong>{notifications?.customerName}</strong>
            </li>
            <li>
              <span>Crédito</span>
              <strong>{currencyMoney(notifications?.creditValue || 0)}</strong>
            </li>
            <li>
              <span>Días</span>
              <strong>{notifications?.days || 0}</strong>
            </li>
            <li>
              <span>Cuota</span>
              <strong>{currencyMoney(notifications?.fee || 0)}</strong>
            </li>
            <li>
              <span>Valor total</span>
              <strong>{currencyMoney(notifications?.amountTotal || 0)}</strong>
            </li>
          </ul>
        </div>

        <div className="form-button-group transparent">
          <button
            type="submit"
            className="btn btn-danger btn-block btn-lg"
            onClick={handleOnReject}
          >
            Rechazar
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanConfirmationPage;
