import React, { useRef } from "react";
import { IInputOWithdrawModal } from "../types/IInputsComponents";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import AFieldErrorMessage from "../atoms/a-field-error-message";
import { alertError } from "../../utils/alerts";
import { createWithdraw } from "../../pages/routes/services";
import { ICreateWithdraw } from "../../pages/routes/types";

const OWithdrawModal = (props: IInputOWithdrawModal) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateWithdraw>();

  const onSubmit: SubmitHandler<ICreateWithdraw> = async (input) => {
    const result = await createWithdraw({
      ...input,
      amount: Number(input.amount),
      routeId: props.routeId,
    });

    if (result.status === true) {
      props.onClose();
      window.location.reload();
    } else {
      alertError(result.message);
    }
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Registrar retiro</h5>
        <div className="card-text">
          <form onSubmit={handleSubmit(onSubmit)}>
            <button
              ref={buttonRef}
              type="submit"
              id="submit"
              style={{ display: "none" }}
            />

            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="date">
                  Fecha
                </label>
                <input
                  {...register("date", { required: true })}
                  type="date"
                  className="form-control"
                  id="date"
                  min={dayjs().subtract(2, "day").format("YYYY-MM-DD")}
                  max={props.queryDate}
                  defaultValue={props.queryDate}
                  readOnly={true}
                />
              </div>
              {errors.date && <AFieldErrorMessage />}
            </div>

            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="comments">
                  Descripción
                </label>
                <input
                  {...register("comments", { required: true })}
                  type="text"
                  className="form-control"
                  id="comments"
                  autoComplete="off"
                />
              </div>
              {errors.comments && <AFieldErrorMessage />}
            </div>

            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="amount">
                  Valor
                </label>
                <input
                  {...register("amount", { required: true })}
                  type="number"
                  className="form-control"
                  id="amount"
                  autoComplete="off"
                  placeholder="* 1000"
                />
              </div>
              {errors.amount && <AFieldErrorMessage />}
            </div>
          </form>
        </div>
      </div>
      <div className="card-footer">
        <div className="btn-group d-flex">
          <button onClick={props.onClose} className="btn btn-secondary">
            Cancelar
          </button>
          <button onClick={handleOnSave} className="btn btn-success">
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default OWithdrawModal;
