/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import { ICreateLoan } from "./types";
import { SubmitHandler, useForm } from "react-hook-form";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import dayjs from "dayjs";
import { alertError, alertSuccess } from "../../utils/alerts";
import { getCustomerInfo } from "../users/services";
import { createLoan, getLoansListSelect } from "./services";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MULTIPLICATOR } from "../../utils/constants";
import Select from "react-select";

const CreateLoansPage = () => {
  const params = useParams()!;
  const navigate = useNavigate();
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<ICreateLoan>();
  const [activeTab, setActiveTab] = useState("customer");
  const [canSave, setCanSave] = useState(false);
  const [positions, setPositions] = useState([]);
  const [typePosition, setTypePosition] = useState("first");
  const [position, setPosition] = useState(0);
  const typePositions = [
    { value: "first", label: "De Primero" },
    { value: "after", label: "Después de..." },
    { value: "before", label: "Antes de..." },
  ];

  const onSubmit: SubmitHandler<ICreateLoan> = async (input) => {
    if (!canSave) return alertError("Por favor corregir los datos con errores");

    let _position = position;
    if (typePosition === "first") _position = 1;
    else if (typePosition === "before") {
      _position -= 1;
      if (_position <= 0) _position = 1;
    } else {
      _position += 1;
      if (_position === 0) _position = 1;
    }

    const result = await createLoan({
      ...input,
      credit: Number(input.credit),
      days: Number(input.days),
      return: Number(input.return),
      fee: Number(input.fee),
      position: _position,
      routeId: params?.id ?? defaultRoute.id,
    });

    if (result.status === true) {
      alertSuccess(result.message);
      navigate("/loans");
    } else {
      alertError(result.message);
    }
  };
  const getLoans = async () => {
    const { data, status } = await getLoansListSelect(
      params?.id ?? defaultRoute.id,
      queryDate
    );

    if (status === true) {
      setPositions(
        data.items.map((item: any) => ({
          label: `[ ${item.position} ] ${item.customerName}`,
          value: item.position,
        }))
      );
    }
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };
  const handleOnKeyUp = (event: any) => {
    let _return = 0;
    const [credit, days, fee] = getValues(["credit", "days", "fee"]);

    if (credit && days && fee) {
      _return = fee * MULTIPLICATOR * days;
      if (_return < credit * MULTIPLICATOR) {
        setError("return", {
          message: "El Total a devolver no puede ser menor al Crédito",
        });
        setCanSave(false);
      } else {
        setCanSave(true);
        clearErrors();
      }
    } else {
      setCanSave(false);
    }

    setValue("return", _return / MULTIPLICATOR);
  };
  const handleOnBlur = async () => {
    const document = getValues("document");
    if (document) {
      const { data: response } = await getCustomerInfo(document);

      if (response.data?.document) {
        setValue("name", response.data.name);
        setValue("lastname", response.data.lastname);
        setValue("phone", response.data.phone);
        setValue("address", response.data.address);
        setValue("neighborhood", response.data.neighborhood);
        setValue("city", response.data.city);
      } else {
        reset();
        setValue("document", document);
      }
    }

    setValue("beginDate", queryDate);
  };

  useEffect(() => {
    getLoans();
  }, []);

  return (
    <>
      <OHeaderBackSave
        title="Crear préstamo"
        pathToBack="/loans"
        onSave={handleOnSave}
      />

      <div className="form-custom">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button
            ref={buttonRef}
            type="submit"
            id="submit"
            style={{ display: "none" }}
          />
          <ul className="nav nav-tabs lined" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#customer"
                role="tab"
                onClick={() => setActiveTab("customer")}
              >
                Cliente
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#credit"
                role="tab"
                onClick={() => setActiveTab("credit")}
              >
                Crédito
              </a>
            </li>
          </ul>
          <div className="tab-content mt-2">
            <div
              id="customer"
              role="tabpanel"
              className={
                activeTab === "customer"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
            >
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="document">
                    Documento
                  </label>
                  <input
                    {...register("document", { required: true })}
                    type="text"
                    className="form-control"
                    id="document"
                    onBlur={handleOnBlur}
                    autoComplete="false"
                  />
                </div>
                {errors.document && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="name">
                    Nombre
                  </label>
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    className="form-control"
                    id="name"
                    autoComplete="false"
                  />
                </div>
                {errors.name && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="lastname">
                    Apellidos
                  </label>
                  <input
                    {...register("lastname", { required: true })}
                    type="text"
                    className="form-control"
                    id="lastname"
                    autoComplete="false"
                  />
                </div>
                {errors.lastname && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="phone">
                    Celular
                  </label>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    className="form-control"
                    id="phone"
                    autoComplete="false"
                  />
                </div>
                {errors.phone && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="address">
                    Dirección
                  </label>
                  <input
                    {...register("address", { required: true })}
                    type="text"
                    className="form-control"
                    id="address"
                    autoComplete="false"
                  />
                </div>
                {errors.address && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="neighborhood">
                    Barrio
                  </label>
                  <input
                    {...register("neighborhood", { required: true })}
                    type="text"
                    className="form-control"
                    id="neighborhood"
                    autoComplete="false"
                  />
                </div>
                {errors.neighborhood && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="city">
                    Ciudad
                  </label>
                  <input
                    {...register("city", { required: true })}
                    type="text"
                    className="form-control"
                    id="city"
                    autoComplete="false"
                  />
                </div>
                {errors.city && <AFieldErrorMessage />}
              </div>
            </div>

            <div
              id="credit"
              role="tabpanel"
              className={
                activeTab === "credit"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
            >
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="typePosition">
                    Posición
                  </label>

                  <Select
                    defaultValue={typePositions[0]}
                    id="typePosition"
                    options={typePositions}
                    onChange={(option: any) => setTypePosition(option.value)}
                  />
                  {typePosition !== "first" && (
                    <>
                      <br />
                      <Select
                        options={positions}
                        onChange={(option: any) => setPosition(option.value)}
                      />
                    </>
                  )}
                </div>
                {errors.position && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="credit">
                    Crédito
                  </label>
                  <input
                    {...register("credit", { required: true })}
                    type="number"
                    className="form-control"
                    id="credit"
                    placeholder="* 1000"
                    onKeyUp={handleOnKeyUp}
                  />
                </div>
                {errors.credit && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="days">
                    Días
                  </label>
                  <input
                    {...register("days", { required: true })}
                    type="number"
                    className="form-control"
                    id="days"
                    onKeyUp={handleOnKeyUp}
                  />
                </div>
                {errors.days && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="fee">
                    Cuota
                  </label>
                  <input
                    {...register("fee", { required: true })}
                    type="number"
                    className="form-control"
                    id="fee"
                    placeholder="* 1000"
                    onKeyUp={handleOnKeyUp}
                  />
                </div>
                {errors.fee && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="return">
                    Total a devolver
                  </label>
                  <input
                    {...register("return", { required: true })}
                    type="number"
                    className="form-control"
                    id="return"
                    placeholder="* 1000"
                  />
                </div>
                {errors.return && (
                  <AFieldErrorMessage text={errors.return.message} />
                )}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="beginDate">
                    Fecha de inicio
                  </label>
                  <input
                    {...register("beginDate", { required: true })}
                    type="text"
                    className="form-control"
                    id="beginDate"
                    defaultValue={queryDate}
                    disabled={true}
                  />
                </div>
                {errors.beginDate && (
                  <AFieldErrorMessage text={errors.beginDate.message} />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateLoansPage;
