import React, { useState } from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import { menuOutline, addOutline, searchOutline } from "ionicons/icons";
import { openDrawer } from "../../slices/drawer";
import { useDispatch } from "react-redux";
import { IInputOHeader } from "../types/IInputsComponents";

const OHeaderListActionPage = (props: IInputOHeader) => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("");

  const handleOnClickMenu = () => {
    dispatch(openDrawer(true));
  };

  const handleOnChange = (event: any) => {
    setFilter(event.target.value);
  };

  const handleOnKeyUp = (event: any) => {
    if (event.key === "Enter") {
      props.dispatch(filter);
    }
  };

  return (
    <>
      <div className="appHeader o-header-list">
        {props.isShowIcon && (
          <div className="left">
            <button onClick={handleOnClickMenu} className="headerButton">
              <IonIcon className="ion-icon" icon={menuOutline} />
            </button>
          </div>
        )}
        <div className="pageTitle">{props.title}</div>
        {props.isShowIcon && (
          <div className="right">
            <button onClick={props.handleAction} className="headerButton">
              <IonIcon className="ion-icon" icon={props.icon ?? addOutline} />
            </button>
          </div>
        )}
      </div>

      {props.isShowSearchBox && (
        <div className="extraHeader">
          <form
            className="search-form"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="form-group searchbox">
              <input
                type="text"
                className="form-control"
                onChange={handleOnChange}
                value={filter}
                onKeyUp={handleOnKeyUp}
              />
              <i className="input-icon">
                <IonIcon
                  icon={searchOutline}
                  role="img"
                  className="md hydrated"
                  aria-label="search outline"
                />
              </i>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

OHeaderListActionPage.defaultProps = {
  isShowIcon: true,
  isShowSearchBox: true,
};

export default OHeaderListActionPage;
