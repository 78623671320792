/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { closeOutline, trashOutline } from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import currencyMoney from "../../utils/currency-money";
import dayjs from "dayjs";
import MLoadMore from "../../components/molecules/m-load-more";
import OHeaderListActionPage from "../../components/organisms/o-header-list-action-page";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { getInjections, removeInjection } from "./services";
import { IInjections, IInjectionsProps } from "./types";
import { useSelector } from "react-redux";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { can } from "../../utils/security";
import { IonIcon } from "@ionic/react";
import { alertError, alertSuccess } from "../../utils/alerts";

const InjectionsPage = (props: IInjectionsProps) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [list, setList] = useState([]);

  const getList = async (page: number) => {
    const response = await getInjections(
      defaultRoute.id,
      props.date ?? queryDate,
      {
        filter,
        page,
        limit: DEFAULT_LIMIT,
      }
    );
    setList(
      page === 1 ? response.data.items : [...list, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getList(page);
  };
  const handleActionPage = async () => {
    navigate("/dashboard");
  };
  const handleOnClickDelete = async (id: number) => {
    const response = await removeInjection(id, {
      routeId: defaultRoute.id,
      date: queryDate,
    });

    if (response.status === true) {
      alertSuccess(response.message);
      getList(1);
    } else {
      alertError(response.message);
    }
  };

  useEffect(() => {
    getList(1);
  }, [filter]);

  return (
    <>
      <OHeaderListActionPage
        title="Inyecciones"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
        isShowIcon={props.isShowIcon}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!list.length && <ADataEmpty />}

        {list.length > 0 && (
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Comentario</th>
                <th>Usuario</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((injection: IInjections, index: number) => {
                return (
                  <tr key={`injection-${index}`}>
                    <td>{injection.comments}</td>
                    <td>{injection.userName}</td>
                    <td>{currencyMoney(injection.amount)}</td>
                    <td>
                      {can("api-injections-delete_injection") &&
                        injection.date == queryDate && (
                          <IonIcon
                            onClick={() => handleOnClickDelete(injection.id)}
                            size="large"
                            icon={trashOutline}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

InjectionsPage.defaultProps = {
  isShowIcon: true,
};

export default InjectionsPage;
