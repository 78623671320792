/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { getDashboardPayments, removePayment } from "./services";
import MLoadMore from "../../components/molecules/m-load-more";
import { IPaymentsToday } from "./types";
import { closeOutline, trashOutline } from "ionicons/icons";
import OHeaderListActionPage from "../../components/organisms/o-header-list-action-page";
import currencyMoney from "../../utils/currency-money";
import dayjs from "dayjs";
import { alertError, alertSuccess } from "../../utils/alerts";
import { IExpenseAndWithdrawProps } from "../routes/types";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { IonIcon } from "@ionic/react";
import { can } from "../../utils/security";

const PaymentsPage = (props: IExpenseAndWithdrawProps) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const { user } = useSelector((state: any) => state.auth);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [payments, setPayments] = useState([]);

  const getPaymentsToday = async (page: number) => {
    const response = await getDashboardPayments(
      defaultRoute.id,
      props.date ?? queryDate,
      {
        filter,
        page,
        limit: DEFAULT_LIMIT,
      }
    );
    setPayments(
      page === 1 ? response.data.items : [...payments, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => {
    getPaymentsToday(page);
  };
  const handleActionPage = async () => {
    navigate("/dashboard");
  };
  const handleRemovePayment = async (paymentId: number) => {
    const { status, message } = await removePayment(paymentId, {
      routeId: defaultRoute.id,
      date: queryDate,
    });

    if (status) {
      alertSuccess(message);
      getPaymentsToday(1);
    } else {
      alertError(message);
    }
  };

  useEffect(() => {
    getPaymentsToday(1);
  }, [filter]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListActionPage
        title="Abonos"
        filter={filter}
        dispatch={setFilter}
        icon={closeOutline}
        handleAction={handleActionPage}
        isShowIcon={props.isShowIcon}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        {!payments.length && <ADataEmpty />}

        {payments.length > 0 && (
          <table className="table table-responsive">
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Crédito</th>
                <th>Cuota</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment: IPaymentsToday, index: number) => {
                return (
                  <tr key={`payment-${index}`}>
                    <td>{payment.customerName}</td>
                    <td>
                      {currencyMoney(payment.credit)},{" "}
                      {currencyMoney(payment.fee)} x {payment.days}
                    </td>
                    <td>{currencyMoney(payment.amount)}</td>
                    <td>
                      {can("api-payments-delete_payment") &&
                        payment.date == queryDate && (
                          <IonIcon
                            onClick={() => handleRemovePayment(payment.id)}
                            size="large"
                            icon={trashOutline}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

PaymentsPage.defaultProps = {
  isShowIcon: true,
};

export default PaymentsPage;
