/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import MAdminCardSummaryInfo from "../molecules/m-admin-card-summary-info";
import { IDashBalance } from "../../pages/dashboard/types";
import { useNavigate } from "react-router-dom";

const OAdminCardSummaryInfo = (props: IDashBalance) => {
  const navigate = useNavigate();

  const handleClickPayments = () => {
    navigate("/loans/payments");
  };
  const handleClickWithdraw = () => {
    navigate("/routes/expenses-and-withdraw");
  };
  const handleClickInjections = () => {
    navigate("/routes/injections");
  };
  const handleClickLoans = () => {
    navigate("/loans/approved");
  };

  return (
    <>
      <div className="row mt-2">
        <MAdminCardSummaryInfo
          text="Abonos"
          amount={props.payments}
          textType="text-success"
          onClick={handleClickPayments}
        />
        <MAdminCardSummaryInfo
          text="Egresos"
          amount={props.expenses + props.withdraw}
          textType="text-danger"
          onClick={handleClickWithdraw}
        />
      </div>
      <div className="row mt-2">
        <MAdminCardSummaryInfo
          text="Inyecciones"
          amount={props.injections}
          textType="text-dark"
          onClick={handleClickInjections}
        />
        <MAdminCardSummaryInfo
          text="Préstamos"
          amount={props.loans}
          textType="text-dark"
          onClick={handleClickLoans}
        />
      </div>
    </>
  );
};

export default OAdminCardSummaryInfo;
