/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import currencyMoney from "../../utils/currency-money";
import { IInputOProgressRouteDashboard } from "../types/IInputsComponents";
import { IDashProgressRoute } from "../../pages/dashboard/types";

const OProgressRouteDashboard = (props: IInputOProgressRouteDashboard) => {
  return (
    <>
      <div className="section-heading">
        <h2 className="title">Progreso de ruta</h2>
      </div>
      <div className="goals">
        {props.items?.map((route: IDashProgressRoute) => {
          return (
            <div className="item" key={`progress-route-${route.routeId}`}>
              <div className="in">
                <div>
                  <h4>Ruta {route.name}</h4>
                  <p>{route.lastPayment}</p>
                </div>
                <div className="price">
                  <span
                    className={
                      route.payments >= route.loans
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {currencyMoney(route.payments)}
                  </span>{" "}
                  /{" "}
                  <span className="text-success">
                    {currencyMoney(route.loans)}
                  </span>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${route.progress}%` }}
                >
                  {route.progress}%
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OProgressRouteDashboard;
