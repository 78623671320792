import React, { useState } from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import {
  menuOutline,
  addOutline,
  searchOutline,
  personAddOutline,
  createOutline,
} from "ionicons/icons";
import { openDrawer } from "../../slices/drawer";
import { useDispatch } from "react-redux";
import { IInputOHeader } from "../types/IInputsComponents";
import { useNavigate } from "react-router-dom";
import { can } from "../../utils/security";

const OHeaderListPage = (props: IInputOHeader) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");

  const handleOnClickMenu = () => {
    dispatch(openDrawer(true));
  };

  const handleOnClickAdd = () => {
    navigate(props.path!);
  };

  const handleOnChange = (event: any) => {
    setFilter(event.target.value);
  };

  const handleOnKeyUp = (event: any) => {
    if (event.key === "Enter") {
      props.dispatch(filter);
    }
  };

  return (
    <>
      <div className="appHeader o-header-list">
        <div className="left">
          <button onClick={handleOnClickMenu} className="headerButton">
            <IonIcon className="ion-icon" icon={menuOutline} />
          </button>
        </div>
        <div className="pageTitle">{props.title}</div>
        {(props.hideRightIcon === false ||
          props.hideRightIcon === undefined) && (
          <div className="right">
            {props.isRoutePage && can("api-routes-create_route") && (
              <>
                <button
                  onClick={() => navigate("/routes/create")}
                  className="headerButton"
                >
                  <IonIcon
                    className="ion-icon"
                    icon={addOutline}
                  />
                </button>
                <button
                  onClick={() => navigate("/routes/edit")}
                  className="headerButton"
                >
                  <IonIcon
                    className="ion-icon"
                    icon={createOutline}
                  />
                </button>
              </>
            )}
          </div>
        )}
      </div>

      <div className="extraHeader">
        <form
          className="search-form"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div className="form-group searchbox">
            <input
              type="text"
              className="form-control"
              onChange={handleOnChange}
              value={filter}
              onKeyUp={handleOnKeyUp}
            />
            <i className="input-icon">
              <IonIcon
                icon={searchOutline}
                role="img"
                className="md hydrated"
                aria-label="search outline"
              />
            </i>
          </div>
        </form>
      </div>
    </>
  );
};

export default OHeaderListPage;
