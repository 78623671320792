import { createSlice } from "@reduxjs/toolkit";
import { PREFIX_AUTH } from "../../pages/login/services/auth";
import dayjs from "dayjs";

const queryDate = localStorage.getItem(`${PREFIX_AUTH}-query-date`);
const initialState = {
  queryDate: queryDate ?? dayjs().format("YYYY-MM-DD"),
};

const queryDateSlice = createSlice({
  name: "queryDate",
  initialState,
  reducers: {
    setQueryDate: (state, action) => {
      return { queryDate: action.payload };
    },
  },
});

const { reducer, actions } = queryDateSlice;

export const { setQueryDate } = actions;
export default reducer;
