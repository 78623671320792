import { login, get } from "../../../services/api";
import { IFormLogin } from "../types/IFormLogin";
import { IUser } from "../types/IUser";
import dayjs from "dayjs";

export const PREFIX_AUTH = "copp-user";

export const authenticate = async (
  input: IFormLogin
): Promise<IUser | null> => {
  try {
    const { status, data } = await login({
      endpoint: "auth/login",
      body: input,
    });

    if (status) {
      localStorage.setItem(
        `${PREFIX_AUTH}-token`,
        data.security.token.split("|")[1]
      );
      localStorage.setItem(
        `${PREFIX_AUTH}-abilities`,
        JSON.stringify(data.security.abilities)
      );
      localStorage.setItem(`${PREFIX_AUTH}-data`, JSON.stringify(data.user));
      localStorage.setItem(
        `${PREFIX_AUTH}-query-date`,
        dayjs().format("YYYY-MM-DD")
      );

      return data.user as IUser;
    }

    return null;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
