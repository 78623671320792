export const CathErrorResponse = (error: any): string => {
  let errorMessage = "Error no controlado";

  if (error?.response) {
    if (error.response?.status === 400) {
      const { message } = error.response.data;

      if (Array.isArray(message)) errorMessage = message[0];
      else errorMessage = message;
    } else if (error.response?.status === 500) {
      errorMessage = error.response.data.message;
    } else if (error.response?.status === 422) {
      errorMessage = error.response.data.message;
    }
  }

  if (
    errorMessage === "password too weak" ||
    errorMessage === "Invalid password"
  )
    return "Credenciales no válidas";
  if (errorMessage === "email must be an email") return "Correo no válido";

  return errorMessage;
};
