/* eslint-disable array-callback-return */
import React, { useState } from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import { closeOutline, peopleOutline, trashOutline } from "ionicons/icons";
import {
  pieChartOutline,
  cardOutline,
  logOutOutline,
  receiptOutline,
  swapVerticalOutline,
} from "ionicons/icons";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "../../slices/drawer";
import { PREFIX_AUTH } from "../../pages/login/services/auth";
import { getRoutes } from "../../pages/routes/services";
import OSelectRouteModal from "../../components/organisms/o-select-route-modal";
import dayjs from "dayjs";
import { setQueryDate } from "../../slices/query-date";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { can } from "../../utils/security";

const SidebarLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drawer } = useSelector((state: any) => state.drawer);
  const { user } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [isOpen, setIsOpen] = useState(false);
  const [routes, setRoutes] = useState([]);

  const drawerStyle = drawer
    ? {
        display: "block",
      }
    : {};
  const itemList = [
    {
      canSee: true,
      text: "Dashboard",
      icon: <IonIcon icon={pieChartOutline} />,
      onClick: () => {
        handleCloseDrawer();
        navigate("/dashboard");
      },
    },
    {
      canSee: true,
      text: "Cobros",
      icon: <IonIcon icon={cardOutline} />,
      onClick: () => {
        handleCloseDrawer();
        navigate("/loans");
      },
    },
    {
      canSee: true,
      text: "Secuenciar",
      icon: <IonIcon icon={swapVerticalOutline} />,
      onClick: () => {
        handleCloseDrawer();
        navigate("/to-secuence");
      },
    },
    {
      canSee: can("api-liquidations-create_liquidation"),
      text: "Liquidaciones",
      icon: <IonIcon icon={receiptOutline} />,
      onClick: () => {
        handleCloseDrawer();
        navigate("/collections");
      },
    },
    {
      canSee: can("api-auth-create_user"),
      text: "Usuarios",
      icon: <IonIcon icon={peopleOutline} />,
      onClick: () => {
        handleCloseDrawer();
        navigate("/users");
      },
    },
    {
      canSee: true,
      text: "Limpiar caché",
      icon: <IonIcon icon={trashOutline} />,
      onClick: () => {
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        localStorage.removeItem(`${PREFIX_AUTH}-token`);
        localStorage.removeItem(`${PREFIX_AUTH}-data`);
        localStorage.removeItem(`${PREFIX_AUTH}-route`);
        window.location.href = "/auth";
      },
    },
    {
      canSee: true,
      text: "Cerrar sesión",
      icon: <IonIcon icon={logOutOutline} />,
      onClick: () => {
        localStorage.removeItem(`${PREFIX_AUTH}-token`);
        localStorage.removeItem(`${PREFIX_AUTH}-data`);
        localStorage.removeItem(`${PREFIX_AUTH}-route`);
        localStorage.removeItem(`${PREFIX_AUTH}-query-date`);
        window.location.href = "/auth";
      },
    },
  ];

  const handleCloseDrawer = () => {
    dispatch(openDrawer(false));
  };
  const handleOnClick = async () => {
    handleCloseDrawer();
    const { data } = await getRoutes({
      filter: "",
      page: 1,
      limit: DEFAULT_LIMIT,
    });
    setRoutes(data.items);
    setIsOpen(true);
  };
  const handleOnChangeDate = async (event: any) => {
    localStorage.setItem(`${PREFIX_AUTH}-query-date`, event.target.value);
    await dispatch(setQueryDate(event.target.value) as any);
    window.location.reload();
  };

  return (
    <>
      <OSelectRouteModal isOpen={isOpen} routes={routes} />

      <Drawer
        className="drawer"
        variant="persistent"
        anchor="left"
        open={drawer}
      >
        <div
          className="modal show fade panelbox panelbox-left"
          style={drawerStyle}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="profileBox pt-2 pb-2">
                  <div className="in">
                    <strong>
                      {user?.customerName} {user?.customerLastname}
                    </strong>
                    <div className="text-muted" onClick={handleOnClick}>
                      RUTA <span>{defaultRoute?.name}</span>
                    </div>
                    <div className="form-group boxed">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="collectionDate">
                          Fecha de consulta
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="collectionDate"
                          min={dayjs().subtract(2, "day").format("YYYY-MM-DD")}
                          max={dayjs().format("YYYY-MM-DD")}
                          defaultValue={
                            queryDate ?? dayjs().format("YYYY-MM-DD")
                          }
                          onChange={handleOnChangeDate}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-link btn-icon sidebar-close"
                    data-bs-dismiss="modal"
                    onClick={handleCloseDrawer}
                  >
                    <IonIcon icon={closeOutline} />
                  </button>
                </div>

                <hr />

                <div className="listview-title mt-1">Menu</div>
                <ul className="listview flush transparent no-line image-listview">
                  {itemList.map((item, index) => {
                    const { text, icon, onClick, canSee } = item;

                    if (canSee) {
                      return (
                        <li onClick={onClick} key={`sidebar-${index}`}>
                          <div className="item">
                            <div className="icon-box bg-primary">{icon}</div>
                            <div className="in">{text}</div>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarLayout;
