import React from "react";
import "./styles.css";
import { IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";

const ADataEmpty = () => {
  return (
    <>
      <div className="data-empty__container">
        <IonIcon icon={informationCircle} size="large" />
        <span>No hay datos disponibles</span>
      </div>
    </>
  );
};

export default ADataEmpty;
