import { get } from "../../../services/api";
import { IResponse } from "../../../services/types";

export const getBalance = async (
  routeId: number,
  queryDate: string
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "dashboard/balance",
      params: {
        routeId,
        date: queryDate,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getProgressRoute = async (
  routeId: number,
  queryDate: string,
  limit: number = 1
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "dashboard/progress-route",
      params: {
        routeId,
        date: queryDate,
        limit,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
