import { get, post, put } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";
import { DEFAULT_LIMIT } from "../../../utils/constants";
import { ICreateUser, IEditUser } from "../types";

export const getUsers = async (params: IServiceParams): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "users/list",
      params,
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getUser = async (id: number): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: `users/${id}/detail`,
      params: {
        limit: DEFAULT_LIMIT,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getCollectors = async (module: string): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: `users/collectors`,
      params: {
        module,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createUser = async (body: ICreateUser): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "auth/register",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const editUser = async (
  id: number,
  body: IEditUser
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `users/${id}/edit`,
      id,
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const getCustomerInfo = async (document: string): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: `customers/info/${document}`,
      params: {},
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getUserCollectors = async (
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "users/collectors",
      params: {
        ...params,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
