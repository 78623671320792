import { createSlice } from "@reduxjs/toolkit";
import { PREFIX_AUTH } from "../../pages/login/services/auth";

const route = localStorage.getItem(`${PREFIX_AUTH}-route`);
const initialState = {
  route: route ? JSON.parse(route) : null,
};

const defaultRouteSlice = createSlice({
  name: "defaultRoute",
  initialState,
  reducers: {
    setDefaultRoute: (state, action) => {
      return { route: action.payload };
    },
  },
});

const { reducer, actions } = defaultRouteSlice;

export const { setDefaultRoute } = actions;
export default reducer;
