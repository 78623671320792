import { get, post } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";
import { MULTIPLICATOR } from "../../../utils/constants";
import { ICreateCollection } from "../types";

export const getResumeToCollect = async (
  routeId: number,
  date: string
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "liquidations/balance-to-liquidate",
      params: { routeId, date },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createCollection = async (
  payload: ICreateCollection
): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "liquidations/create",
      body: {
        routeId: Number(payload.routeId),
        injections: Number(payload.injections) / MULTIPLICATOR,
        payments: Number(payload.payments) / MULTIPLICATOR,
        loans: Number(payload.loans) / MULTIPLICATOR,
        expenses: Number(payload.expenses) / MULTIPLICATOR,
        withdrawls: Number(payload.withdrawls) / MULTIPLICATOR,
        balance: Number(payload.balance) / MULTIPLICATOR,
        amountDelivered: Number(payload.amountDelivered),
        date: payload.date,
      },
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const getCollections = async (
  routeId: number,
  date: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "liquidations/list",
      params: {
        ...params,
        date,
        routeId,
      },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
