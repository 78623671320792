import React, { useRef } from "react";
import { IInputOChangePosition } from "../types/IInputsComponents";
import { SubmitHandler, useForm } from "react-hook-form";
import { IUpdatePosition } from "../../pages/loans/types";
import AFieldErrorMessage from "../atoms/a-field-error-message";

const OChangePosition = (props: IInputOChangePosition) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdatePosition>();

  const onSubmit: SubmitHandler<IUpdatePosition> = async ({ newPosition }) => {
    props.onSave(props.loanId, newPosition);
    props.onClose();
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Cambiar la Posición</h5>
          <div className="card-text">
            <form onSubmit={handleSubmit(onSubmit)}>
              <button
                ref={buttonRef}
                type="submit"
                id="submit"
                style={{ display: "none" }}
              />
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="newPosition">
                    Ingrese posición donde quedará el préstamo
                  </label>
                  <input
                    {...register("newPosition", { required: true })}
                    type="number"
                    className="form-control"
                    id="newPosition"
                    autoComplete="off"
                  />
                </div>
                {errors.newPosition && <AFieldErrorMessage />}
              </div>
            </form>
          </div>
        </div>
        <div className="card-footer">
          <div className="btn-group d-flex">
            <button onClick={props.onClose} className="btn btn-secondary">
              Cancelar
            </button>
            <button onClick={handleOnSave} className="btn btn-success">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OChangePosition;
