import { get, post, put } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";
import { IRemove } from "../../routes/types";
import { ICreateLoan, ICreatePayment } from "../types";
import dayjs from "dayjs";

export const getLoans = async (
  routeId: number,
  listFlag: string,
  date: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "loans/route",
      params: { ...params, routeId, date, listFlag },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getLoansInRoute = async (
  routeId: number,
  listFlag: string,
  params: IServiceParams,
  orderBy: string = "position"
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "loans/route",
      params: { ...params, routeId, orderBy, listFlag },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getDashboardPayments = async (
  routeId: number,
  paymentDate: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "payments/dashboard-list",
      params: { ...params, routeId, date: paymentDate },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getPaymentsTodayInTheRoute = async (
  routeId: number,
  paymentDate: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "payments/today",
      params: { ...params, routeId, paymentDate },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getPaymentsHistoryByLoan = async (
  loanId: number,
  date: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "payments/history",
      params: { ...params, loanId, date },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createLoan = async (body: ICreateLoan): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "loans/create",
      body,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const createPayment = async (
  body: ICreatePayment
): Promise<IResponse> => {
  try {
    const response = await post({
      endpoint: "payments/create",
      body: {
        ...body,
      },
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const waitingList = async (
  routeId: number,
  date: string,
  loanId: number
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: "loans/waiting-list",
      id: routeId,
      body: {
        loanId,
        date,
      },
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const reorderList = async (routeId: number): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `loans/${routeId}/reorder-list`,
      id: routeId,
      body: {},
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const updatePosition = async (
  loanId: number,
  routeId: number,
  newPosition: number
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `loans/${loanId}/change-position`,
      id: loanId,
      body: {
        routeId,
        position: newPosition,
      },
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const removePayment = async (
  paymentId: number,
  payload: IRemove
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `payments/${paymentId}/delete`,
      id: paymentId,
      body: payload,
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};

export const getLoansApproved = async (
  routeId: number,
  date: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "loans/approved",
      params: { ...params, routeId, date },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getDashboardLoans = async (
  routeId: number,
  date: string,
  params: IServiceParams
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "loans/dashboard-list",
      params: { ...params, routeId, date },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getLoansListSelect = async (
  routeId: number,
  date: string
): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "loans/route",
      params: { routeId, date, listFlag: "all" },
    });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const changeLoanStatus = async (
  loanId: number,
  status: string
): Promise<IResponse> => {
  try {
    const response = await put({
      endpoint: `loans/${loanId}/edit-status`,
      id: loanId,
      body: {
        status,
      },
    });

    return response as IResponse;
  } catch (error: any) {
    return {
      status: false,
      message: error.message,
    } as IResponse;
  }
};
