/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import OHeaderListPage from "../../components/organisms/o-header-list-page";
import {
  changeLoanStatus,
  createPayment,
  getLoans,
  waitingList,
} from "./services";
import MLoadMore from "../../components/molecules/m-load-more";
import { ILoansInRoute } from "./types";
import currencyMoney from "../../utils/currency-money";
import { SwipeableList, SwipeableListItem } from "react-swipeable-list";
import {
  loansLeadingActions,
  loansTrailingActions,
} from "../../components/organisms/o-loans-swipe-actions";
import { alertError, alertSuccess } from "../../utils/alerts";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { IonIcon } from "@ionic/react";
import {
  addOutline,
  alertCircleOutline,
  calendarNumberOutline,
  callOutline,
  checkboxOutline,
  copyOutline,
  informationOutline,
  peopleOutline,
  readerOutline,
  syncOutline,
  todayOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import OMoreInfoLoanModal from "../../components/organisms/o-more-info-loan-modal";

dayjs.extend(duration);

const tabs = [
  {
    icon: <IonIcon size="large" icon={todayOutline} />,
    tabRef: "todayList",
    tab: "today",
  },
  {
    icon: <IonIcon size="large" icon={calendarNumberOutline} />,
    tabRef: "waitList",
    tab: "wait",
  },
  {
    icon: <IonIcon size="large" icon={checkboxOutline} />,
    tabRef: "paidList",
    tab: "paid",
  },
  {
    icon: <IonIcon size="large" icon={alertCircleOutline} />,
    tabRef: "pendingList",
    tab: "pending",
  },
  {
    icon: <IonIcon size="large" icon={peopleOutline} />,
    tabRef: "allList",
    tab: "all",
  },
];

const LoansPage = () => {
  const params = useParams()!;
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [loans, setLoans] = useState([]);
  const [listFlag, setListFlag] = useState("today");
  const [listTitle, setListTitle] = useState("Ruta diaria");

  const getLoansConfirmedInRoute = async (page: number) => {
    const response = await getLoans(defaultRoute.id, listFlag, queryDate, {
      filter,
      page,
      limit: 50,
    });
    setLoans(
      page === 1 ? response.data.items : [...loans, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const deleteLoanById = (loanId: number) => {
    setLoans(loans.filter((loan: ILoansInRoute) => loan.id !== loanId));
  };
  const loadMore = (page: number) => {
    getLoansConfirmedInRoute(page);
  };
  const calculateDays = (
    beginDate: string,
    paid: number,
    fee: number
  ): number => {
    const fechaInicio = dayjs(beginDate);
    const fechaFin = dayjs();
    const diferencia = fechaFin.diff(fechaInicio);
    const diferenciaEnDias = diferencia / (1000 * 60 * 60 * 24);
    const days = Math.round(diferenciaEnDias);
    const shouldPaid = fee * days;
    const daysLate = Math.round((shouldPaid - paid) / fee);

    return daysLate < 0 ? 0 : daysLate;
  };
  const setClass = (loan: ILoansInRoute): string => {
    if (listFlag === "pending") return "card card-border";

    const days = calculateDays(loan.beginDate, loan.paid, loan.fee);
    let color = "days-older";
    if (days < 10) color = "days-10";
    if (days >= 10 && days < 15) color = "days-20";
    if (days >= 15 && days < 20) color = "days-30";

    return `card card-border ${color}`;
  };
  const savePayment = async (loanId: number, amount: number) => {
    return createPayment({
      loanId,
      amount,
      date: queryDate,
      comments: "ABONO",
      routeId: defaultRoute.id,
    });
  };
  const handleOnClickConfirm = async (loanId: number, amount: number) => {
    let result: any;
    if (listFlag !== "pending") {
      result = await savePayment(loanId, amount);
    } else {
      result = await changeLoanStatus(loanId, "APPROVED");
    }

    if (result.status === true) {
      alertSuccess(result.message);
      deleteLoanById(loanId);
    } else {
      alertError(result.message);
    }
  };
  const scheduled = async (loanId: number) => {
    return await waitingList(defaultRoute.id, queryDate, loanId);
  };
  const handleOnClickWaitingList = async (loanId: number) => {
    let result: any;

    if (listFlag !== "pending") {
      result = await scheduled(loanId);
    } else {
      result = await changeLoanStatus(loanId, "REJECTED");
    }

    if (result.status === true) {
      alertSuccess(result.message);
      getLoansConfirmedInRoute(1);
    } else {
      alertError(result.message);
    }
  };
  const handleOnClicCopy = (document: string) => {
    navigator.clipboard
      .writeText(document)
      .then(() => {
        alertSuccess("Documento copiado con éxito!", 1500);
      })
      .catch((err) => {
        console.error("Error al copiar el texto: ", err);
      });
  };
  const handleOnClicPaymentsHistory = (loanId: number) => {
    navigate(`/loans/${defaultRoute.id}/history/${loanId}`);
  };
  const handleOnclickMoreInfo = (loan: ILoansInRoute) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OMoreInfoLoanModal
            loan={loan}
            onClose={onClose}
            onClickPayments={handleOnClicPaymentsHistory}
          />
        );
      },
    });
  };
  const handleOnClickTab = (flag: string) => {
    setListFlag(flag);
    switch (flag) {
      case "wait":
        setListTitle("Ruta re-programada");
        break;
      case "paid":
        setListTitle("Ruta pagada");
        break;
      case "pending":
        setListTitle("Ruta en espera de aprobación");
        break;
      case "all":
        setListTitle("Todos los clientes del día");
        break;
      default:
        setListTitle("Ruta diaria");
        break;
    }
  };

  useEffect(() => {
    getLoansConfirmedInRoute(1);
  }, [filter, listFlag]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListPage
        path=""
        title="Cobros"
        filter={filter}
        dispatch={setFilter}
        isRoutePage={true}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>

        <div className="section mt-1 mb-2">
          <div className="row">
            <div className="col-10">
              <div className="exampleBox radius">
                <ul className="nav nav-tabs lined" role="tablist">
                  {tabs.map((item: any, index: number) => {
                    return (
                      <li className="nav-item">
                        <a
                          className={
                            item.tab === listFlag
                              ? "nav-link active"
                              : "nav-link"
                          }
                          data-bs-toggle="tab"
                          href={`#${item.tabRef}`}
                          role="tab"
                          onClick={() => handleOnClickTab(item.tab)}
                        >
                          {item.icon}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-2">
              <div className="filter">
                <button
                  type="button"
                  className="btn btn-icon btn-primary me-1"
                  onClick={() => getLoansConfirmedInRoute(1)}
                >
                  <IonIcon size="large" icon={syncOutline} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="listview-title mb-1">{listTitle}</div>
        {!loans.length && <ADataEmpty />}

        <SwipeableList>
          {loans.map((loan: ILoansInRoute) => {
            return (
              <SwipeableListItem
                leadingActions={loansLeadingActions(
                  loan,
                  queryDate,
                  listFlag === "pending",
                  handleOnClickConfirm
                )}
                trailingActions={loansTrailingActions(
                  loan,
                  handleOnClickWaitingList,
                  listFlag === "pending"
                )}
                key={`loan-${loan.id}`}
              >
                <div className={setClass(loan)}>
                  <div className="card-body">
                    <h5 className="card-title">
                      <span className="badge badge-primary">
                        {loan.position}
                      </span>
                      {loan.customerName}
                    </h5>
                    <div className="card-text">
                      <div className="card-custom-item">
                        <span>Documento</span>
                        <span style={{ alignItems: "center", display: "flex" }}>
                          {loan.document}
                          <IonIcon
                            style={{
                              marginLeft: "10px",
                            }}
                            icon={copyOutline}
                            onClick={() => handleOnClicCopy(loan.document)}
                          />
                        </span>
                      </div>
                      <div className="card-custom-item">
                        <span>Celular</span>
                        <span style={{ alignItems: "center", display: "flex" }}>
                          {loan.phone}
                          <a href={`tel:${loan.phone}`}>
                            <IonIcon
                              style={{
                                marginLeft: "10px",
                              }}
                              icon={callOutline}
                            />
                          </a>
                        </span>
                      </div>
                      <div className="card-custom-item">
                        <span>Cuota</span>
                        <span>{currencyMoney(loan.fee)}</span>
                      </div>
                      {listFlag !== "pending" && (
                        <div className="card-custom-item">
                          <span>Pagado</span>
                          <span
                            style={{ alignItems: "center", display: "flex" }}
                          >
                            {currencyMoney(loan.paid)}
                            <IonIcon
                              style={{
                                marginLeft: "10px",
                              }}
                              icon={readerOutline}
                              onClick={() =>
                                handleOnClicPaymentsHistory(loan.id)
                              }
                            />
                          </span>
                        </div>
                      )}
                      {listFlag !== "pending" && (
                        <div className="card-custom-item">
                          <span>Días de atrazo</span>
                          <span>
                            {calculateDays(loan.beginDate, loan.paid, loan.fee)}
                          </span>
                        </div>
                      )}

                      {loan.address}

                      <div className="card-custom-item">
                        <span>Más info...</span>
                        <span style={{ alignItems: "center", display: "flex" }}>
                          <IonIcon
                            style={{
                              marginLeft: "10px",
                            }}
                            icon={informationOutline}
                            onClick={() => handleOnclickMoreInfo(loan)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableListItem>
            );
          })}
        </SwipeableList>

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />

        <button
          className="button fixed-button"
          onClick={() => {
            navigate(`/loans/create/${defaultRoute.id}`);
          }}
        >
          +
        </button>
      </div>
    </>
  );
};

export default LoansPage;
