import { get } from "../../../services/api";
import { IResponse, IServiceParams } from "../../../services/types";

export const getRoles = async (params: IServiceParams): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "roles/list",
      params,
    });

    return response;
  } catch (error: any) {
    return {
      status: false,
      data: [],
      message: error.message,
    } as IResponse;
  }
};

export const getPermissions = async (): Promise<IResponse> => {
  try {
    const response = await get({
      endpoint: "roles/permissions",
      params: {},
    });

    return response;
  } catch (error: any) {
    return {
      status: false,
      data: [],
      message: error.message,
    } as IResponse;
  }
};
