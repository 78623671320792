/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import OHeaderListPage from "../../components/organisms/o-header-list-page";
import { getUsers } from "./services";
import { IUsers } from "./types";
import MLoadMore from "../../components/molecules/m-load-more";
import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { can } from "../../utils/security";

const UsersPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [users, setUsers] = useState([]);

  const getUsersList = async (page: number) => {
    const response = await getUsers({ filter, page, limit: DEFAULT_LIMIT });
    setUsers(
      page === 1 ? response.data.items : [...users, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const handleOnClick = (id: number) => {
    navigate(`/users/edit/${id}`);
  };
  const loadMore = (page: number) => {
    getUsersList(page);
  };

  useEffect(() => {
    getUsersList(1);
  }, [filter]);

  if (!isLoggedIn) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <OHeaderListPage
        path="/users/create"
        title="Usuarios"
        filter={filter}
        dispatch={setFilter}
      />

      <div id="appCapsule">
        <div className="listview-title mt-2">Listado</div>
        <ul className="listview image-listview inset">
          {users.map((user: IUsers) => {
            return (
              <li className="listview--border" key={`user-${user.id}`}>
                <div className="item">
                  <div className="in">
                    <div className="uppercase">
                      <header>{user.roleName}</header>
                      <span>
                        {user.name} {user.lastname}
                      </span>
                      <br />
                      <span>
                        Cel. <a href={`tel:${user.phone}`}>{user.phone}</a>
                      </span>
                      <footer>
                        {user.address}, {user.neighborhood}
                      </footer>
                    </div>
                    {can("api-users-edit_user") && (
                      <span
                        onClick={() => handleOnClick(user.id)}
                        className="text-muted text-muted--center"
                      >
                        Editar
                        <IonIcon icon={chevronForwardOutline} />
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>

        <MLoadMore
          currentPage={pagination.currentPage}
          totalPages={pagination.lastPage}
          dispatch={loadMore}
        />
      </div>
    </>
  );
};

export default UsersPage;
