import React, { useEffect, useRef, useState } from "react";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import { SubmitHandler, useForm } from "react-hook-form";
import { ICreateUser, IGroupedPermissions, IPermission } from "./types";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import { createUser } from "./services";
import { useNavigate } from "react-router-dom";
import { alertError, alertSuccess } from "../../utils/alerts";
import { getPermissions, getRoles } from "../roles/services";
import { IRoles } from "../roles/types";
import { DEFAULT_LIMIT } from "../../utils/constants";

const modulesLNG: any = {
  auth: "Usuarios",
  customers: "Clientes",
  roles: "Roles",
  routes: "Rutas",
  injections: "Inyecciones",
  loans: "Préstamos",
  expenses: "Gastos",
  withdrawls: "Retiros",
  payments: "Pagos",
  liquidations: "Liquidaciones",
};

const CreateUserPage = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("info");
  const [roles, setRoles] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState(
    {} as IGroupedPermissions
  );
  const [selectedPermissions, setSelectedPermissions] = useState(
    [] as Array<number>
  );

  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ICreateUser>();

  const onSubmit: SubmitHandler<ICreateUser> = async (input) => {
    const result = await createUser({
      ...input,
      roleId: Number(input.roleId),
      permissions: selectedPermissions,
    });

    if (result.status === true) {
      alertSuccess(result.message);
      navigate("/users");
    } else {
      alertError(result.message);
    }
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };
  const loadRoles = async () => {
    const [roles, _permissions] = await Promise.all([
      getRoles({
        filter: "",
        page: 1,
        limit: DEFAULT_LIMIT,
      }),
      getPermissions(),
    ]);
    setRoles(roles.data.items);
    const permissions: IPermission[] = _permissions.data.items;

    const _groupedPermissions: IGroupedPermissions = permissions.reduce(
      (acc, permission) => {
        // Separar el code por guiones y obtener el segundo índice
        const parts = permission.code.split("-");
        const groupKey = parts.length > 1 ? parts[1] : "Unknown";

        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(permission);
        return acc;
      },
      {} as IGroupedPermissions
    );

    setGroupedPermissions(_groupedPermissions);
  };
  const handleCheckboxChange = (id: number) => {
    setSelectedPermissions((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((permId) => permId !== id)
        : [...prevSelected, id]
    );
  };

  useEffect(() => {
    loadRoles();
  }, []);

  return (
    <>
      <OHeaderBackSave
        title="Crear usuario"
        pathToBack="/users"
        onSave={handleOnSave}
      />

      <div className="form-custom">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button
            ref={buttonRef}
            type="submit"
            id="submit"
            style={{ display: "none" }}
          />

          <ul className="nav nav-tabs lined" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#info"
                role="tab"
                onClick={() => setActiveTab("info")}
              >
                Info Básica
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#access"
                role="tab"
                onClick={() => setActiveTab("access")}
              >
                Acceso
              </a>
            </li>
          </ul>

          <div className="tab-content mt-2">
            <div
              id="info"
              role="tabpanel"
              className={
                activeTab === "info"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
            >
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="document">
                    Documento
                  </label>
                  <input
                    {...register("document", { required: true })}
                    type="text"
                    className="form-control"
                    id="document"
                  />
                </div>
                {errors.document && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="name">
                    Nombre
                  </label>
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    className="form-control"
                    id="name"
                  />
                </div>
                {errors.name && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="lastname">
                    Apellido
                  </label>
                  <input
                    {...register("lastname", { required: true })}
                    type="text"
                    className="form-control"
                    id="lastname"
                  />
                </div>
                {errors.lastname && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="phone">
                    Celular
                  </label>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    className="form-control"
                    id="phone"
                  />
                </div>
                {errors.phone && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="address">
                    Dirección
                  </label>
                  <input
                    {...register("address", { required: false })}
                    type="text"
                    className="form-control"
                    id="address"
                  />
                </div>
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="neighborhood">
                    Barrio
                  </label>
                  <input
                    {...register("neighborhood", { required: false })}
                    type="text"
                    className="form-control"
                    id="neighborhood"
                  />
                </div>
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="city">
                    Ciudad
                  </label>
                  <input
                    {...register("city", { required: false })}
                    type="text"
                    className="form-control"
                    id="city"
                  />
                </div>
              </div>
            </div>

            <div
              id="access"
              role="tabpanel"
              className={
                activeTab === "access"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
            >
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="email">
                    Email
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    className="form-control"
                    id="email"
                  />
                </div>
                {errors.email && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="password1">
                    Contraseña
                  </label>
                  <input
                    {...register("password", { required: false })}
                    type="password"
                    className="form-control"
                    id="password1"
                  />
                </div>
                {errors.password && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="password2">
                    Confirmar nueva contraseña
                  </label>
                  <input
                    {...register("confirmPassword", {
                      required: false,
                      validate: (value) =>
                        value === getValues("password") ||
                        "La contraseña es diferente",
                    })}
                    type="password"
                    className="form-control"
                    id="password2"
                  />
                </div>
                {errors.confirmPassword && (
                  <AFieldErrorMessage text={errors.confirmPassword.message} />
                )}
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="role">
                    Rol
                  </label>
                  <select
                    {...register("roleId", { required: true })}
                    id="role"
                    className="form-control"
                  >
                    {roles.map((role: IRoles) => {
                      return (
                        <option key={`role-${role.id}`} value={role.id}>
                          {role.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.roleId && <AFieldErrorMessage />}
              </div>

              <div className="form-group boxed">
                <h3>Permisos</h3>
                <div className="input-list">
                  {Object.entries(groupedPermissions).map(
                    ([module, _permission]) => (
                      <div key={module}>
                        <h4>{modulesLNG[module]}</h4>
                        {_permission.map((permission: any) => (
                          <div className="form-check" key={permission.id}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`permission-${permission.id}`}
                              onChange={() =>
                                handleCheckboxChange(permission.id)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`permission-${permission.id}`}
                            >
                              {permission.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateUserPage;
