/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./styles.css";
import OHeaderListPage from "../../components/organisms/o-header-list-page";
import { IonIcon } from "@ionic/react";
import { trendingDownOutline, trendingUpOutline } from "ionicons/icons";
import currencyMoney from "../../utils/currency-money";
import MLoadMore from "../../components/molecules/m-load-more";
import { getCollections } from "./services";
import { useSelector } from "react-redux";
import { ICollections } from "./types";
import dayjs from "dayjs";
import ADataEmpty from "../../components/atoms/a-data-empty";
import { DEFAULT_LIMIT } from "../../utils/constants";

const CollectionsPage = () => {
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    lastPage: 0,
  });
  const [collections, setCollections] = useState([]);
  const { route: defaultRoute } = useSelector(
    (state: any) => state.defaultRoute
  );
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const getList = async (page: number) => {
    const response = await getCollections(defaultRoute.id, queryDate, {
      filter,
      page,
      limit: DEFAULT_LIMIT,
    });
    setCollections(
      page === 1
        ? response.data.items
        : [...collections, ...response.data.items]
    );
    setPagination(response.data.metadata);
  };
  const loadMore = (page: number) => getList(page);

  useEffect(() => {
    getList(1);
  }, [filter]);

  return (
    <>
      <OHeaderListPage
        path="/collections/create"
        title="Liquidaciones"
        filter={filter}
        dispatch={setFilter}
      />

      <div id="appCapsule">
        <div className="section mt-2">
          <div className="section-title">Listado</div>

          {!collections.length && <ADataEmpty />}

          {collections.length > 0 && (
            <div className="card">
              <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                {collections.map((item: ICollections, index: number) => {
                  return (
                    <li className="listview--border" key={`item-${index}`}>
                      <a className="item">
                        <div
                          className={
                            item.amountDelivered > item.lastAmountDelivered
                              ? "icon-box bg-success"
                              : "icon-box bg-danger"
                          }
                        >
                          <IonIcon
                            icon={
                              item.amountDelivered > item.lastAmountDelivered
                                ? trendingUpOutline
                                : trendingDownOutline
                            }
                          />
                        </div>
                        <div className="in">
                          <div>
                            <strong>RUTA {item.routeName}</strong>
                            <div className="text-small text-secondary">
                              {item.userName}
                            </div>
                          </div>
                          <div className="text-end">
                            <strong>
                              {currencyMoney(item.amountDelivered)}
                            </strong>
                            <div className="text-small">
                              {dayjs(item.date).format(
                                "MMM DD, YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>

              <MLoadMore
                currentPage={pagination.currentPage}
                totalPages={pagination.lastPage}
                dispatch={loadMore}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionsPage;
