import React, { useEffect, useRef, useState } from "react";
import OHeaderBackSave from "../../components/organisms/o-header-back-save";
import { SubmitHandler, useForm } from "react-hook-form";
import AFieldErrorMessage from "../../components/atoms/a-field-error-message";
import { useNavigate } from "react-router-dom";
import { alertError, alertSuccess } from "../../utils/alerts";
import { getUserCollectors } from "../users/services";
import { ICreateRoute, ICollector } from "./types";
import { createRoute } from "./services";
import { DEFAULT_LIMIT } from "../../utils/constants";
import { useSelector } from "react-redux";
import Select from "react-select";

const CreateRoutePage = () => {
  const navigate = useNavigate();
  const { queryDate } = useSelector((state: any) => state.queryDate);

  const [collectors, setCollectors] = useState([]);
  const [collectorId, setCollectorId] = useState(null);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateRoute>();

  const onSubmit: SubmitHandler<ICreateRoute> = async (input) => {
    if (!collectorId) {
      return alertError("Debe seleccionar un Cobrador");
    }

    const result = await createRoute({
      name: input.name,
      collectorId: Number(collectorId),
      baseAmount: Number(input.baseAmount),
      date: queryDate,
    });

    if (result.status === true) {
      alertSuccess(result.message);
      navigate("/loans");
    } else {
      alertError(result.message);
    }
  };
  const handleOnSave = () => {
    const buttonElement = buttonRef.current!;
    buttonElement.click();
  };
  const loadCollectors = async () => {
    const collectors = await getUserCollectors({
      page: 1,
      limit: DEFAULT_LIMIT,
    });
    setCollectors(
      collectors.data.items.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  useEffect(() => {
    loadCollectors();
  }, []);

  return (
    <>
      <OHeaderBackSave
        title="Crear ruta"
        pathToBack={"/loans"}
        onSave={handleOnSave}
      />

      <div className="form-custom">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button
            ref={buttonRef}
            type="submit"
            id="submit"
            style={{ display: "none" }}
          />
          <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="label" htmlFor="name">
                Nombre
              </label>
              <input
                {...register("name", { required: true, maxLength: 15 })}
                type="text"
                className="form-control"
                id="name"
              />
            </div>
            {errors.name && <AFieldErrorMessage />}
          </div>

          <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="label" htmlFor="collector">
                Cobrador
              </label>
              <Select
                options={collectors}
                onChange={(option: any) => setCollectorId(option.value)}
              />
            </div>
            {errors.collectorId && <AFieldErrorMessage />}
          </div>

          <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="label" htmlFor="baseAmount">
                Base
              </label>
              <input
                {...register("baseAmount", { required: true })}
                type="number"
                className="form-control"
                id="baseAmount"
                placeholder="* 1000"
              />
            </div>
            {errors.baseAmount && <AFieldErrorMessage />}
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateRoutePage;
